import { atom } from 'recoil';
import {
  LaboratoryTestTubeIcon,
  MedicalCardIcon,
  TrayIcon,
  UserFoldersIcon,
} from '../../assets/icons/Icons';

export const HeaderState = atom({
  key: 'HeaderState',
  readonly: false,
  default: [
    {
      name: 'Products',
      isActive: false,
      isDropDown: true,
      children: [
        {
          name: 'Siroca Patient Engagement',
          description: 'Improve Patient experience at scale',
          icon: <TrayIcon />,
          link: '/siroca-patient-engagement',
        },
        {
          name: 'Siroca For Diagnostics',
          description: 'Improve Patient experience at scale',
          icon: <LaboratoryTestTubeIcon />,
          link: '/siroca-for-diagnostics',
        },
        {
          name: 'Siroca Technology PACS',
          description: 'Improve Patient experience at scale',
          icon: <UserFoldersIcon />,
          link: '/siroca-technology-pacs',
        },
        {
          name: 'Siroca <br/> Inventory',
          description: 'Improve Patient experience at scale',
          icon: <MedicalCardIcon />,
          link: '/siroca-inventory',
        },
      ],
    },
    {
      name: 'About Us',
      isActive: false,
      isDropDown: false,
      link: '/about-us',
      children: [],
    },
    {
      name: 'Implementation',
      isActive: false,
      isDropDown: false,
      link: '/implementation',
      children: [],
    },
    {
      name: 'Integrations',
      isActive: false,
      isDropDown: false,
      link: '/integrations',
    },
  ],
});
