import './VideoPlayerSection.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Title from '../../../../components/Title/Title';

import SirocaImage from '../../../../assets/images/Implementation/siroca.png';

const VideoPlayerSection = ({ title, cards }) => {
  return (
    <div className="VideoPlayer">
      <PageContainer>
        <div className="VideoPlayer__wrapper">
          <Title>{title}</Title>
          <div className="VideoPlayer__flexxer">
            <img src={SirocaImage} alt="" />

            <div className="VideoPlayer__wrapper__cards">
              {cards.map(({ title, description }) => (
                <div className="VideoPlayer__row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="26"
                    viewBox="0 0 20 26"
                    fill="none"
                  >
                    <path
                      d="M9.2 2.06667C9.6 1.53333 10.4 1.53333 10.8 2.06667L18.55 12.4C18.8167 12.7556 18.8167 13.2444 18.55 13.6L10.8 23.9333C10.4 24.4667 9.6 24.4667 9.2 23.9333L1.45 13.6C1.18333 13.2444 1.18333 12.7556 1.45 12.4L9.2 2.06667Z"
                      stroke="#115AFB"
                      stroke-width="2"
                    />
                    <path
                      d="M10 4C10 3.03899 11.2234 2.63119 11.8 3.4L18.55 12.4C18.8167 12.7556 18.8167 13.2444 18.55 13.6L11.8 22.6C11.2234 23.3688 10 22.961 10 22V4Z"
                      fill="#115AFB"
                      stroke="#115AFB"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="VideoPlayer__row__text">
                    <Title lineHeight={'24px'} sizeFont={'18px'}>
                      {title}
                    </Title>

                    <Subtitle>{description}</Subtitle>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default VideoPlayerSection;
