import React from 'react';
import Title from '../../../../../../components/Title/Title';
import Subtitle from '../../../../../../components/Subtitle/Subtitle';

import './StickyBlockCard.scss';

const StickyBlockCard = ({ title, description, images, id, imgColumns }) => {
  return (
    <div className="StickyBlockCard your-scrollable-elements" id={id}>
      <Title sizeFont={'24px'}>{title}</Title>
      <Subtitle>{description}</Subtitle>
      <div className="StickyBlockCard__images">
        {images.map(({ image, i }) => (
          <img className={`per-${imgColumns}`} src={image} key={i} alt=""/>
        ))}
      </div>
    </div>
  );
};

export default StickyBlockCard;
