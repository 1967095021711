import React from 'react';
import PageTitle from './../../components/PageTitle/PageTitle.jsx';
import MedicalSoftwareSupport from './../../pages/Siroca/components/MedicalSoftwareSupport/MedicalSoftwareSupport.jsx';
import HomePageTitle from './../../assets/images/Siroca/HomePageImg.png';
import Solutions from './../../pages/Siroca/components/Solutions/Solutions.jsx';
import HealthcareSoftwareCertifications from './../../pages/Siroca/components/HealthcareSoftwareCertifications/HealthcareSoftwareCertifications.jsx';
import AboutSiroca from './../../pages/Siroca/components/AboutSiroca/AboutSiroca.jsx';
import OurProducts from './../../pages/Siroca/components/OurProducts/OurProducts.jsx';
import OurStatistic from './../../pages/Siroca/components/OurStatistic/OurStatistic.jsx';
import MakeYourLabFuture from './../../pages/Siroca/components/MakeYourLabFuture/MakeYourLabFuture.jsx';
import CustomerFeedbackPanel from './../../pages/Siroca/components/CustomerFeedbackPanel/CustomerFeedbackPanel.jsx';
import { useRecoilValue } from 'recoil';
import { OurProductsState } from '../../recoil/OurProducts/OurProducts.js';

import MedicalSoftwareSupportImage from './../../assets/images/Siroca/MedicalDiagnostics.png';
import MedicalSoftwareSupportImageMobile from './../../assets/images/Siroca/MedicalDiagnosticsMobile.png';

import { useMediaQuery } from '@mui/material';

const Siroca = () => {
  const OurProductsData = useRecoilValue(OurProductsState);

  const isMobile = useMediaQuery('(max-width: 633px)');

  return (
    <div className="Siroca">
      <PageTitle
        title={'Siroca Next-Level Lab Management'}
        description={
          'Precision at Your Fingertips, Where Technology Meets Diagnostics Excellence.'
        }
        image={HomePageTitle}
        bigTitle
      />
      <OurStatistic />
      <AboutSiroca />
      <Solutions whiteMode />
      <MedicalSoftwareSupport
        whiteMode
        reverse
        title={
          'Continuous Expert Assistance: Your Medical Diagnostics Software Supported 24/7'
        }
        description={
          'We provide ongoing support after your purchase. Set up your facility, train staff, and address any troubleshooting needs in the Diagnostic Lab Software.'
        }
        image={
          isMobile
            ? MedicalSoftwareSupportImageMobile
            : MedicalSoftwareSupportImage
        }
      />
      <HealthcareSoftwareCertifications />
      <OurProducts title={'Our products'} cards={OurProductsData} />
      <CustomerFeedbackPanel />
      <MakeYourLabFuture />
    </div>
  );
};

export default Siroca;
