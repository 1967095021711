import React from 'react';
import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';
import { ButtonPrimaryWithAction } from '../../../../components/Button/Buttons';

import './MakeYourLabFuture.scss';
import { useSetRecoilState } from 'recoil';
import { ContactUsPopupState } from '../../../../recoil/ContactUsPopup/ContactUsState';

const MakeYourLabFuture = () => {
  const setShowContact = useSetRecoilState(ContactUsPopupState);
  return (
    <div className="make-your-lab-future">
      <div className="container">
        <div className="make-your-lab-future__wrapper">
          <div className="make-your-lab-future__wrapper__text">
            <Title color={'#1F1F1F'}>
              Engage in a chat with our experienced team to explore new <br />
              laboratory features designed to manage organizational <br />
              complexities.
            </Title>

            <Description lineHeight={'24px'}>
              Explore the capabilities through a live demonstration. Reach out
              to us to clarify any questions and test the features. Our team is
              always ready to support and guide you through the process as you
              discover the full potential of our offering and gain confidence in
              the power of our solutions to meet your specific needs.
            </Description>
          </div>

          <ButtonPrimaryWithAction action={() => setShowContact(true)}>
            Contact Us
          </ButtonPrimaryWithAction>
        </div>
      </div>
    </div>
  );
};

export default MakeYourLabFuture;
