import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import ProductCard from './components/ProductCard/SeamlessintegrationCard';
import './Seamlessintegration.scss';
import Description from '../../../../components/Description/Description';
import SeamlessintegrationCard from './components/ProductCard/SeamlessintegrationCard';

const Seamlessintegration = ({ background, cards, title, color , cardsBg, description}) => {
  return (
    <div
      className="Seamlessintegration"
      style={{ backgroundColor: background && background }}
    >
      <PageContainer>
        <div className="Seamlessintegration__wrapper">
          <div className="Seamlessintegration__wrapper-text">
            <Title color={color && color}>{title}</Title>
            <Description color={'#484848'}>{description}</Description>
          </div>
          <div className="Seamlessintegration__wrapper-cards">
            {cards.map((product, index) => (
              <SeamlessintegrationCard
                key={index}
                title={product.title}
                description={product.description}
                cardsBg={cardsBg}
              />
            ))}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Seamlessintegration;
