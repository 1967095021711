import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Description from './../../../../components/Description/Description';
import './MedicalSoftwareSupport.scss';
import { useMediaQuery } from '@mui/material';

const MedicalSoftwareSupport = ({
  title,
  description,
  image,
  whiteMode,
  reverse,
}) => {
  const isMobile = useMediaQuery('(max-width: 545px)');

  return (
    <div
      className={`MedicalSoftwareSupport ${whiteMode ? 'whited' : ''} ${
        reverse ? 'reverse' : ''
      }`}
    >
      <PageContainer>
        <div className="MedicalSoftwareSupport__block">
          <div className="MedicalSoftwareSupport__block-text">
            <Title
              sizeFont={isMobile && '24px'}
              lineHeight={isMobile && '30px'}
              color={'#FFF'}
            >
              {title}
            </Title>
            <Description color={'#FFF'}>{description}</Description>
          </div>
          <div className="MedicalSoftwareSupport__block-img">
            {image && <img src={image} alt="MedicalSoftwareSupportImage" />}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default MedicalSoftwareSupport;
