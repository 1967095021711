import React from 'react';
import './SirocaPatientEngagement.scss';
import PageTitle from '../../components/PageTitle/PageTitle';

import SirocaPatientImage from '../../assets/images/SirocaPatients/SirocaPatientTitle.png';
import Solutions from '../Siroca/components/Solutions/Solutions';
import MakeYourLabFuture from '../Siroca/components/MakeYourLabFuture/MakeYourLabFuture';
import ServiceHighlightSection from '../SirocaForDiagnostics/components/ServiceHighlightSection/ServiceHighlightSection';
import { useRecoilValue } from 'recoil';
import { PersonalSecureExperienceState } from '../../recoil/SirocaPatientEngagement/SirocaPatientEngagement';
import AccordionsSection from './components/DropdownSection/AccordionsSection';
import DropdownImg1 from '../../assets/images/SirocaPatients/dropdown1.png';
import DropdownImg2 from '../../assets/images/SirocaPatients/SirocaEngagementMedical.png';
import DropdownImg3 from '../../assets/images/SirocaPatients/ClickPay.png';

const SirocaPatientEngagement = () => {
  const personalSecureCards = useRecoilValue(PersonalSecureExperienceState);
  return (
    <div className="SirocaPatientEngagement">
      <PageTitle
        title={'Patient Interaction Platform'}
        description={
          'Enhance the quality of patient interactions and connections using our comprehensive patient engagement tool. Guarantee optimal patient retention rates through the facilitation of a smooth digital patient experience.'
        }
        image={SirocaPatientImage}
      />
      <AccordionsSection
        image={DropdownImg1}
        subtitle={'Personalized Reminders and Promotions'}
        accordeons={[
          {
            title: 'Differentiate Patient Groups for Efficient Communication',
            description:
              'Utilize Siroca LIMS to tailor test packages by incorporating holiday promotions, discounts, and personalized special offers.',
          },
          {
            title: 'Creative Approach',
            description:
              'Personalize messages and promotions using branded themes, color codes, and localization to establish a lasting connection with patients',
          },
          {
            title: 'Custom Report Delivery and Feedback Management',
            description:
              'Automatically distribute patients\' digital medical reports through SMS, Whats App, Patient Portal, Patient App. Additionally, gather feedback from patients to enhance the responsiveness and customer-centric nature of your system',
          },
        ]}
        imageHeight={'auto'}
      />
      <AccordionsSection
        subtitle={'Quick Integrations'}
        description={
          'Manage orders placed via our patient communication software seamlessly within Siroca LIMS, without the need for extensive integration processes.'
        }
        image={DropdownImg2}
        accordeons={[
          {
            title: '100+ EMR Integrations available',
            description:
              '"Our top-tier patient scheduling software seamlessly integrates with any EMR system, granting administrative staff precise patient information and instant access to all pertinent medical records.',
          },
          {
            title: 'Flexibility for Patients (Online, Offline, QR)',
            description:
              'Mitigate inaccuracies and cut down on patient wait times using our online scheduling solution. Enable patients to easily choose their desired time slots. Facilitate speedy walk-in appointments through QR code for efficient service.',
          },
          {
            title: 'Extended Service Solutions: For Home Collection and Drive-Through',
            description:
              'Conduct home collections and drive-through services effortlessly with Siroca’s Nurse App and Patient App. These applications enable patients to book appointments, receive status updates via App, SMS, and WhatsApp. Simultaneously, they provide a transparent platform for laboratories to observe the patient experience, handle logistics, and manage nursing personnel.',
          },
        ]}
        reversed
        imageHeight={'auto'}
      />
      <AccordionsSection
        subtitle={'Create Seamless Booking & Payment Experience for patients'}
        description={
          'Create a customized experience for customers to schedule appointments, make payments and engagement channels.'
        }
        image={DropdownImg3}
        accordeons={[
          {
            title: '360 - degrees Experience for Patients',
            description:
              '"Siroca software crafts a comprehensive 360-degree experience for your patients by centralizing LIMS, registration, in-store queues, and utilizing apps for nurses and patients. This cohesive approach ensures a seamless and smooth overall experience for patients',
          },
          {
            title: 'Convert your Website into a Store',
            description:
              'Improve patient results by streamlining the test booking process with our online patient engagement. Quickly and accurately capture essential medical information using our patient experience software.',
          },
          {
            title: 'Protected Payments',
            description:
              'Effortlessly connect our online patient management system with leading payment gateways, ensuring a secure and seamless facilitation of your financial transactions.',
          },
        ]}
        imageHeight={'auto'}
      />

      <ServiceHighlightSection
        background={'#fff'}
        title={'Make the work of Lab Managers simplified'}
        cards={personalSecureCards}
      />
      <Solutions whiteMode />
      <MakeYourLabFuture />
    </div>
  );
};

export default SirocaPatientEngagement;
