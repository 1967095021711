import React from 'react';

import './TestingLabOperationsSectionMobile.scss';
import { NavigateNext } from '@mui/icons-material';
import PageContainer from '../../../../../components/PageContainer/PageContainer';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Title from '../../../../../components/Title/Title';
import Description from '../../../../../components/Description/Description';
import Subtitle from '../../../../../components/Subtitle/Subtitle';

const TestingLabOperationsSectionMobile = ({
  data,
  title,
  description,
  bgColor,
}) => {
  return (
    <div
      className="TestingLabOperationsSectionMobile"
      style={{ background: bgColor }}
    >
      <PageContainer>
        <div className="TestingLabOperationsSectionMobile__wrapper">
          <div className="TestingLabOperationsSectionMobile__wrapper-text">
            <Title>{title}</Title>
            <Description>{description}</Description>
          </div>
          <div className="TestingLabOperationsSectionMobile__wrapper-tabs">
            <div className="TestingLabOperationsSectionMobile__wrapper-tabs__left">
              {data.map((tab, index) => (
                <Accordion className="TestingLabOperationsSectionMobile__wrapper-tabs__left__accordion">
                  <AccordionSummary
                    className="TestingLabOperationsSectionMobile__wrapper-tabs__left__accordion-title"
                    expandIcon={<NavigateNext sx={{ color: '#115AFB' }} />}
                  >
                    {tab.tabName}
                  </AccordionSummary>
                  <AccordionDetails className="TestingLabOperationsSectionMobile__wrapper-tabs__left__accordion-text">
                    <Title sizeFont={'18px'} lineHeight={'24px'}>
                      {tab.title || tab.tabName}
                    </Title>
                    <Subtitle color={'#484848'}>{tab.tabContent}</Subtitle>
                    <div className="TestingLabOperationsSectionMobile__wrapper-tabs__right-panel__image">
                      <img src={tab.tabImage} alt="" />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default TestingLabOperationsSectionMobile;
