import React from 'react';

import './Footer.scss';
import { useRecoilValue } from 'recoil';
import {
  FooterBlockState,
  FooterSocialState,
} from '../../recoil/Footer/Footer';
import Title from '../Title/Title';
import { Link } from 'react-router-dom';
import Subtitle from '../Subtitle/Subtitle';

const Footer = () => {
  const footerBlockState = useRecoilValue(FooterBlockState);
  const footerSocialState = useRecoilValue(FooterSocialState);
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__wrapper__cols">
            {footerBlockState.map(({ title, links }) => (
              <div className="footer__wrapper__cols__col">
                <div className="footer__wrapper__cols__col__title">
                  <Title
                    color={'#1F1F1F'}
                    sizeFont={'24px'}
                    lineHeight={'30px'}
                  >
                    {title}
                  </Title>
                </div>

                {links.map(({ name, to }) => (
                  <Link className="footer__wrapper__cols__col__link" to={to}>
                    {name}
                  </Link>
                ))}
              </div>
            ))}
          </div>

          <div className="footer__wrapper__bottom">
            <Subtitle color={'#484848'}>
              © 2024. SIROCA. Laboratory Information Management System (LIMS)
            </Subtitle>

            <div className="footer__wrapper__bottom__socials">
              {footerSocialState.map(({ image, link }) => (
                <Link
                  to={link}
                  className="footer__wrapper__bottom__socials__icon"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: image,
                    }}
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
