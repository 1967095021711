import React from 'react';
import './SeamlessintegrationCard.scss';
import { ButtonWithIcon } from '../../../../../../components/Button/Buttons';

const SeamlessintegrationCard = ({
  image,
  title,
  description,
  linkTo,
  hideBtn,
  cardsBg,
}) => {
  return (
    <div className="SeamlessintegrationCard" style={{ background: cardsBg && cardsBg }}>
      <h3
        className="SeamlessintegrationCard__title"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <span className="SeamlessintegrationCard__description">{description}</span>
    </div>
  );
};

export default SeamlessintegrationCard;
