import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';

import './ UnfoldingComponent.scss';
import Description from '../../../../components/Description/Description';

const UnfoldingComponent = ({
  bgColor,
  title,
  reversed,
  subtitle,
  description,
  items,
  imageHeight,
  image,
  sectionDescription,
}) => {
  return (
    <div className="UnfoldingSection" style={{ background: bgColor || '#FFF' }}>
      <PageContainer>
        
          { (title || sectionDescription) && 
            <div className="UnfoldingSection__header">
              {title && <Title>{title}</Title>}
              {sectionDescription && (
                <Description>{sectionDescription}</Description>
              )}
            </div>
          }
          {subtitle && <h3 className="UnfoldingSection__text-title">{subtitle}</h3>}
        <div
          className="UnfoldingSection__wrapper"
          style={{ flexDirection: reversed ? 'row-reverse' : 'row' }}
        >
          <div className="UnfoldingSection__text">
            {description && <p className="UnfoldingSection__text-text">{description}</p>}
            <div className="UnfoldingSection__text-accordions">
              {items.map(item => (
                <div className="UnfoldingSection__text-accordions__row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="26"
                    viewBox="0 0 20 26"
                    fill="none"
                  >
                    <path
                      d="M9.2 2.06667C9.6 1.53333 10.4 1.53333 10.8 2.06667L18.55 12.4C18.8167 12.7556 18.8167 13.2444 18.55 13.6L10.8 23.9333C10.4 24.4667 9.6 24.4667 9.2 23.9333L1.45 13.6C1.18333 13.2444 1.18333 12.7556 1.45 12.4L9.2 2.06667Z"
                      stroke="#115AFB"
                      stroke-width="2"
                    />
                    <path
                      d="M10 4C10 3.03899 11.2234 2.63119 11.8 3.4L18.55 12.4C18.8167 12.7556 18.8167 13.2444 18.55 13.6L11.8 22.6C11.2234 23.3688 10 22.961 10 22V4Z"
                      fill="#115AFB"
                      stroke="#115AFB"
                      stroke-width="2"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="UnfoldingSection__text-accordions__row__text">
                    <Title lineHeight={'normal'} sizeFont={'18px'}>
                      {item.title}
                    </Title>

                    <Subtitle>
                      {item.description}
                    </Subtitle>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="UnfoldingSection__image"
            style={{ height: 'auto' }}
          >
            <img src={image} alt="" />
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default UnfoldingComponent;
