import { atom } from 'recoil';

import SirocaEngagementMedical from '../../assets/images/SirocaPatients/SirocaEngagementMedical.png';

export const AdaptableLimsSolutionState = atom({
  key: 'AdaptableLimsSolutionState',
  default: [
    {
      tabName: 'Organization',
      content: [
        {
          title: 'Joint approach',
          tabContent:
            'By pooling strengths and resources, we will arrive at effective solutions, fostering mutual success.',
        },
        {
          title: 'Identifying preferences',
          tabContent:
            'We will tailor the entire process to meet you specific needs.',
        },
        {
          title: 'Tailor made plan',
          tabContent:
            'Develop a comprehensive plan detailing the schedule, recourses and expected outcomes to ensure a seamless deployment for your website.',
        },
      ],
      tabImage: SirocaEngagementMedical,
    },
  ],
});
