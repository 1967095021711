import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Description from '../../../../components/Description/Description';
import './HealthcareSoftwareCertifications.scss';
import Partner2Logo from './../../../../assets/images/HealthcareSoftwareCertifications/Partner2.png';
import Partner3Logo from './../../../../assets/images/HealthcareSoftwareCertifications/Partner3.png';
import Partner4Logo from './../../../../assets/images/HealthcareSoftwareCertifications/Partner4.png';

const HealthcareSoftwareCertifications = ({ background }) => {
  return (
    <div
      className="HealthcareSoftwareCertifications"
      style={{ background: background }}
    >
      <PageContainer>
        <div className="HealthcareSoftwareCertifications__wrapper">
          <div className="HealthcareSoftwareCertifications__wrapper-text">
            <Title title={'#1f1f1f'}>
              Advanced Security And Compliance Features Of Siroca Diagnostic
              Laboratory Software
            </Title>
            <Subtitle>
              Ensuring Benchmark Security & Safety For Your Business
            </Subtitle>
            <Description>
              Siroca LIMS Diagnostic Laboratory Software strives for
              international benchmarks like HIPAA and GDPR, ensuring strong data
              security and privacy. Our software utilizes advanced encryption
              for storing, accessing, and communicating data, ensuring the
              confidentiality of sensitive information. We maintain an internal
              team dedicated to collaborating with the regulatory requirements
              of any country and adapting to meet local compliance standards
            </Description>
          </div>
          <div className="HealthcareSoftwareCertifications__wrapper-images">
            <div className="HealthcareSoftwareCertifications__wrapper-images__item">
              <img src={Partner2Logo} alt="Partner Logo" />
            </div>
            <div className="HealthcareSoftwareCertifications__wrapper-images__item">
              <img src={Partner3Logo} alt="Partner Logo" />
            </div>
            <div className="HealthcareSoftwareCertifications__wrapper-images__item">
              <img src={Partner4Logo} alt="Partner Logo" />
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default HealthcareSoftwareCertifications;
