import React from 'react';

import './CrelioHealthPacs.scss';
import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';

const CrelioHealthPacs = ({ title, cards, short }) => {
  return (
    <div className="CrelioHealthPacs">
      <div className="container">
        <div className="CrelioHealthPacs__wrapper">
          <Title>
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />{' '}
          </Title>

          <div className="CrelioHealthPacs__wrapper__cards">
            {cards.map(item => (
              <div
                className={`CrelioHealthPacs__wrapper__cards__card ${short ? 'short' : ''}`}
              >
                <div className="CrelioHealthPacs__wrapper__cards__card__imgContainer">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.icon,
                    }}
                  />
                </div>
                <div className="CrelioHealthPacs__wrapper__cards__card__text">
                  <Title sizeFont={'42px'}>{item.title}</Title>

                  <Subtitle color={'#B5B5B5'}>{item.description}</Subtitle>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrelioHealthPacs;
