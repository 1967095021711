import { atom } from 'recoil';

import InvivoDash from '../../assets/images/SirocaForDiagnostics/invivoDash.png';
import LISDiscount from '../../assets/images/SirocaForDiagnostics/LISDiscount.png';
import LISReporting from '../../assets/images/SirocaForDiagnostics/LISReporting.png';

export const FinancialWorkflowState = atom({
  key: 'financialWorkflow',
  default: [
    {
      tabName: 'Payment Collection Online or Offline',
      tabContent:
        "Streamline the payment collection process, whether it's done online or offline, and keep up-to-date records with our sophisticated LIMS (Laboratory Information Management System). Facilitate report deliveries and handle overdue collections through automated procedures. Seamlessly incorporate online payment collections and generate automatic invoices for smooth transactions between business partners and patients.",
      tabImage: InvivoDash,
    },
    {
      tabName: 'Test Prices and Promotions on Target Groups',
      tabContent:
        'Effectively handling pricing across different business sources, departments, outsourcing centers, and B2Bs is essential. Our laboratory LIMS System manages this task by enabling straightforward management of seasonal pricing from a centralized location. Moreover, our laboratory billing system offers control over conditional and other discounts for all centers and B2Bs, guaranteeing approval through verification.',
      tabImage: LISDiscount,
    },
    {
      tabName: 'Access Reports across locations',
      tabContent:
        'Accessing a detailed overview of your business spanning multiple locations in formats like Excel, and PDF is just a click away. Efficiently oversee your accounting end-of-day balances and daily collections through reports, which are user-friendly, easily searchable, filterable, exportable, and shareable via the LIMS (Lab Information Management System)',
      tabImage: LISReporting,
    },
    {
      tabName: 'Laboratory Finance Analytics and Insights',
      tabContent:
        'Accurately oversee all your financial components using Siroca’s top-notch LIMS System. This sophisticated system empowers you to manage your financial aspects with effectiveness and precision. Obtain valuable real-time insights into financial data, transactions, and other critical information to make informed decisions. Efficiently track your finances and make prompt decisions that align with your business goals. Access more than 50+ report verticals.',
      tabImage: '',
    },
  ],
});
