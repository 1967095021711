import React from 'react';
import './ProductCard.scss';
import { ButtonWithIcon } from '../../../../../../components/Button/Buttons';

const ProductCard = ({
  image,
  title,
  description,
  linkTo,
  hideBtn,
  cardsBg,
}) => {
  return (
    <div className="ProductCard" style={{ background: cardsBg && cardsBg }}>
      <div
        className="ProductCard__image"
        dangerouslySetInnerHTML={{
          __html: image,
        }}
      />
      <h3
        className="ProductCard__title"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <span className="ProductCard__description">{description}</span>

      {!hideBtn && (
        <>
          <hr />
          <ButtonWithIcon to={linkTo}>Know More</ButtonWithIcon>
        </>
      )}
    </div>
  );
};

export default ProductCard;
