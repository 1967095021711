import React from 'react';
import Header from '../header/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';

const PageLayout = () => {
  return (
    <>
      <Header />
      <div className="content-wrapper">
        <Outlet />
      </div>

      <Footer />
    </>
  );
};

export default PageLayout;
