import React, { forwardRef, useEffect, useRef, useState } from 'react';

import './ContactPopup.scss';
import {
  Autocomplete,
  Dialog,
  IconButton,
  Slide,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { ButtonPrimary } from '../../Button/Buttons';
import { useRecoilState } from 'recoil';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ArrowSelect, CloseIcon } from '../../../assets/icons/Icons';

import emailjs from '@emailjs/browser';
import { ContactUsPopupState } from '../../../recoil/ContactUsPopup/ContactUsState';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactPopup = () => {
  const [open, setOpen] = useRecoilState(ContactUsPopupState);
  const isMobile = useMediaQuery('(max-width: 633px)');
  const form = useRef();

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_o2uie1n',
        'template_zur0pea',
        form.current,
        'MhiWBlUFIepxF4kou',
      )
      .then(() => {
        setOpen(false);
      });
  };

  useEffect(() => {
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code',
    )
      .then(response => response.json())
      .then(data => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      className="ContactPopupDialog"
    >
      <div className="ContactPopupDialog__wrapper">
        {isMobile && (
          <IconButton
            onClick={() => setOpen(false)}
            className="ContactPopupDialog__wrapper__close"
          >
            <CloseIcon />
          </IconButton>
        )}

        <form ref={form} onSubmit={sendEmail}>
          <div className="ContactPopupDialog__wrapper__inputs">
            <TextField name="name" placeholder="Name" />
            <PhoneInput
              inputProps={{
                name: 'phone',
              }}
              country="kg"
              placeholder="Phone"
            />

            <TextField name="email" placeholder="E-mail" />

            <Autocomplete
              options={countries}
              value={selectedCountry}
              getOptionLabel={option => option.label}
              onChange={selectedOption => setSelectedCountry(selectedOption)}
              popupIcon={<ArrowSelect />}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label="Country"
                  variant="outlined"
                  autoComplete="off"
                  name="country"
                  placeholder="Country"
                />
              )}
            />
          </div>
          <div className="ContactPopupDialog__wrapper__inputs__btn">
            <ButtonPrimary type="submit">Submit Your Application</ButtonPrimary>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContactPopup;
