import './index.scss';
import { useLocation, useRoutes } from 'react-router-dom';
import routes from './routing/routes.jsx';
import { useEffect } from 'react';
import PricingPopup from './components/Popups/PricingPopup/PricingPopup.jsx';
import ContactPopup from './components/Popups/ContactPopup/ContactPopup.jsx';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  const content = useRoutes(routes);
  return (
    <>
      {content}
      <PricingPopup />
      <ContactPopup />
    </>
  );
}

export default App;
