import React from 'react';
import './ServiceHighlightSection.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';
import Card from './../../../../components/Cards/Card/Card';

const ServiceHighlightSection = ({ title, description, cards, background }) => {
  return (
    <div className="ServiceHighlightSection" style={{ background: background }}>
      <PageContainer>
        <div className="ServiceHighlightSection__wrapper">
          <div className="ServiceHighlightSection__wrapper-text">
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
          </div>
          <div className="ServiceHighlightSection__wrapper-text__cards">
            {cards.map((card, index) => {
              return (
                <Card
                  key={index}
                  title={card.title}
                  description={card.text}
                  icon={card.icon}
                />
              );
            })}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default ServiceHighlightSection;
