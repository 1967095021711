import React, { useRef } from 'react';

import './StickyBlock.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import { Tab, Tabs } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import StickyBlockCard from './components/StickyBlockCard/StickyBlockCard';
import { useRecoilValue } from 'recoil';
import {
  StickyCards,
  StickyTabs,
} from '../../../../recoil/Intergrations/Intergrations';
import { useMediaQuery } from '@mui/material';
import ScrollSpy from 'react-ui-scrollspy';

const StickyBlock = () => {
  const smallScreen = useMediaQuery('(max-width: 940px)');

  const tabs = useRecoilValue(StickyTabs);
  const cards = useRecoilValue(StickyCards);

  const onPress = e => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split('#')[1],
    );
    if (target) {
      var headerOffset = smallScreen ? 75 : 80;
      var elementPosition = target.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="StickyBlock">
      <PageContainer>
        <div className="StickyBlock__wrapper">
          <div className="StickyBlock__sidebar">
            <div className="StickyBlock__wrapper-tabs">
              <div className="StickyBlock__wrapper-tabs__left">
                <Tabs
                  className="StickyBlock__wrapper-tabs__left-tabulations"
                  orientation={smallScreen ? 'horizontal' : 'vertical'}
                  sx={{ overflow: 'scroll' }}
                >
                  {tabs.map(({ name, id }) => (
                    <a
                      data-to-scrollspy-id={id}
                      onClick={e => onPress(e)}
                      className={` StickyBlock__wrapper-tabs__left-tabulations__wrap`}
                      href={`#${id.toLowerCase()}`}
                    >
                      <Tab
                        icon={<NavigateNext />}
                        iconPosition="end"
                        className={`StickyBlock__wrapper-tabs__left-tabulations__item`}
                        label={name}
                      />
                    </a>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
          <div className="StickyBlock__wrapper-tabs__right StickyBlock__card">
            <div>
              <ScrollSpy
                activeClass="active"
                offsetBottom={100}
                scrollThrottle={80}
                useBoxMethod
              >
                {cards.map(({ title, description, images, id, imgColumns }) => (
                  <StickyBlockCard
                    title={title}
                    description={description}
                    images={images}
                    id={id.toLowerCase()}
                    imgColumns={imgColumns}
                  />
                ))}
              </ScrollSpy>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default StickyBlock;
