import React from 'react';
import './TestingLabOperationsSection.scss';
import Title from '../../../../components/Title/Title';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Description from '../../../../components/Description/Description';
import './TestingLabOperationsSection.scss';
import { Tab, Tabs } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import Subtitle from '../../../../components/Subtitle/Subtitle';

function CustomTabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className={className}>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TestingLabOperationsSection = ({
  data,
  bgColor,
  title,
  description,
  moreCards,
}) => {
  const [tabsValue, setTabsValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <div
      className="TestingLabOperationsSection"
      style={{ background: bgColor }}
    >
      <PageContainer>
        <div className="TestingLabOperationsSection__wrapper">
          <div className="TestingLabOperationsSection__wrapper-text">
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
          </div>
          <div className="TestingLabOperationsSection__wrapper-tabs">
            <div className="TestingLabOperationsSection__wrapper-tabs__left">
              <Tabs
                value={tabsValue}
                onChange={handleChange}
                className="TestingLabOperationsSection__wrapper-tabs__left-tabulations"
                orientation="vertical"
              >
                {data.map((tab, index) => (
                  <Tab
                    icon={<NavigateNext />}
                    iconPosition="end"
                    className="TestingLabOperationsSection__wrapper-tabs__left-tabulations__item"
                    label={tab.tabName}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </div>
            <div className="TestingLabOperationsSection__wrapper-tabs__right">
              {data.map((panel, index) => (
                <CustomTabPanel
                  value={tabsValue}
                  index={index}
                  className="TestingLabOperationsSection__wrapper-tabs__right-panel"
                >
                  <div className="TestingLabOperationsSection__wrapper-tabs__right-panel__content">
                    {moreCards ? (
                      panel.content.map(item => (
                        <div className="TestingLabOperationsSection__wrapper-tabs__right-panel__text">
                          <Title sizeFont={'24px'} lineHeight={'30px'}>
                            {item.title || item.tabName}
                          </Title>
                          <Subtitle color={'#484848'}>
                            {item.tabContent}
                          </Subtitle>
                        </div>
                      ))
                    ) : (
                      <div className="TestingLabOperationsSection__wrapper-tabs__right-panel__text">
                        <Title sizeFont={'24px'} lineHeight={'30px'}>
                          {panel.title || panel.tabName}
                        </Title>
                        <Subtitle color={'#484848'}>
                          {panel.tabContent}
                        </Subtitle>
                      </div>
                    )}
                  </div>

                  <div className="TestingLabOperationsSection__wrapper-tabs__right-panel__image">
                    <img src={panel.tabImage} alt="" />
                  </div>
                </CustomTabPanel>
              ))}
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default TestingLabOperationsSection;
