import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

import SirocaForDiagnosticsTitle from '../../assets/images/SirocaForDiagnostics/SirocaForDiagnosticsTitle.svg';
import TestingLabOperationsSection from './components/TestingLabOperationsSection/TestingLabOperationsSection';
import { useRecoilValue } from 'recoil';
import { SystemImproves } from '../../recoil/SirocaForDiagnostics/SystemImproves';
import { FinancialWorkflowState } from '../../recoil/SirocaForDiagnostics/FinancialWorkflow';
import Solutions from '../Siroca/components/Solutions/Solutions';
import MakeYourLabFuture from '../Siroca/components/MakeYourLabFuture/MakeYourLabFuture';
import LaboratoryInformation from './components/LaboratoryInformation/LaboratoryInformation';
import ServiceHighlightSection from './components/ServiceHighlightSection/ServiceHighlightSection';
import { ServiceHighlightSectionState } from '../../recoil/SirocaForDiagnostics/ServiceHighlightSection';
import { useMediaQuery } from '@mui/material';
import TestingLabOperationsSectionMobile from './components/TestingLabOperationsSection/MobileVersion/TestingLabOperationsSectionMobile';

const SirocaForDiagnostics = () => {
  const systemImprovesData = useRecoilValue(SystemImproves);
  const financialWorkflowData = useRecoilValue(FinancialWorkflowState);
  const ServiceHighlight = useRecoilValue(ServiceHighlightSectionState);

  const isMobile = useMediaQuery('(max-width: 633px)');

  return (
    <div className="siroca-for-diagnostics">
      <PageTitle
        title={
          'LIMS System to bring Full Lab Automation and Elevate your lab processes.'
        }
        description={
          '"Our Laboratory Information Management System (LIMS) optimizes key operations within your laboratory, improving overall efficiency. This makes the management of various aspects of your lab, such as front desk responsibilities, delivery reporting, and the maintenance of vital lab information, more streamlined and accessible."'
        }
        showPricingBtn
        viewDemoLink={'/'}
        pricingLink={'/'}
        image={SirocaForDiagnosticsTitle}
      />

      {isMobile ? (
        <TestingLabOperationsSectionMobile
          data={systemImprovesData}
          title={
            'Siroca’s LIMS System improves every facet of your testing lab operations'
          }
          description={
            'Siroca LIMS provides a spectrum of advantages to your laboratory business by automating and taking off burden from internal processes. Learn how the Sroca LIMS impacts each step of your process.'
          }
        />
      ) : (
        <TestingLabOperationsSection
          data={systemImprovesData}
          title={
            'Siroca’s LIMS System improves every facet of your testing lab operations'
          }
          description={
            'Siroca LIMS provides a spectrum of advantages to your laboratory business by automating and taking off burden from internal processes. Learn how the Sroca LIMS impacts each step of your process.'
          }
        />
      )}

      {isMobile ? (
        <TestingLabOperationsSectionMobile
          data={financialWorkflowData}
          bgColor={'#EAF0FB'}
          title={
            'Track Financial Workflow with LIMS system for enhanced visibility and control'
          }
          description={
            'Enhance your financial management with the leading LIMS System. This exceptionally effective solution allows you to centralize all operational requirements in one comprehensive application, optimizing your financial administration.'
          }
        />
      ) : (
        <TestingLabOperationsSection
          bgColor={'#EAF0FB'}
          data={financialWorkflowData}
          title={
            'Track Financial Workflow with LIMS system for enhanced visibility and control'
          }
          description={
            'Enhance your financial management with the leading LIMS System. This exceptionally effective solution allows you to centralize all operational requirements in one comprehensive application, optimizing your financial administration.'
          }
        />
      )}

      <LaboratoryInformation />
      <ServiceHighlightSection
        cards={ServiceHighlight}
        background={'#fff'}
        title={'Make the work of Lab Managers simplified'}
      />
      <Solutions whiteMode={true} />
      <MakeYourLabFuture />
    </div>
  );
};

export default SirocaForDiagnostics;
