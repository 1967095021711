import React, { useState } from 'react';
import './DropdownMobile.scss';
import { HeaderState } from '../../../../recoil/Header/Header';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderCard from '../../../Cards/HeaderCard/HeaderCard';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const DropdownMobile = ({ isOpened, onClose, cards }) => {
  const className = `dropdownMobile ${isOpened ? 'opened' : ''}`;
  const headerState = useRecoilValue(HeaderState);

  const [options, setOptions] = useState(headerState);

  const handleAccordionChange = (name, isActive) => {
    const updatedOptions = options.map(item =>
      item.name === name ? { ...item, isActive } : { ...item, isActive: false },
    );
    setOptions(updatedOptions);
  };

  return (
    <div style={{ width: '100%' }} className={className}>
      <nav className="dropdownMobile__nav">
        {options.map((item, index) =>
          item.isDropDown ? (
            <Accordion key={index} className="dropdownMobile__nav-accordion">
              <AccordionSummary className="dropdownMobile__nav-accordion-title">
                <button
                  className="dropdownMobile__nav__link dropdown"
                  onClick={() =>
                    handleAccordionChange(item.name, !item.isActive)
                  }
                >
                  {item.name}
                  <ExpandMoreIcon
                    className={`dropdown__icon__${
                      item.isActive ? 'rotated' : 'default'
                    }`}
                  />
                </button>
              </AccordionSummary>
              <AccordionDetails className="dropdownMobile__cards">
                {item.children.length
                  ? item.children?.map(card => (
                      <HeaderCard
                        key={card.name}
                        text={card.name}
                        description={card.description}
                        icon={card.icon}
                        knowMoreLink={card.link}
                        pricingLink={'/'}
                        onCloseDropdown={onClose}
                      />
                    ))
                  : null}
              </AccordionDetails>
            </Accordion>
          ) : (
            <Link
              key={item.name}
              to={item.link}
              onClick={onClose}
              className="dropdownMobile__nav__link"
            >
              {item.name}
            </Link>
          ),
        )}
      </nav>
    </div>
  );
};

export default DropdownMobile;
