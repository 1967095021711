import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Description from '../../../../components/Description/Description';
import Title from '../../../../components/Title/Title';
import './MapSection.scss';

import MapImg from '../../../../assets/images/AboutUs/map-img.svg';
import MapImgMobile from '../../../../assets/images/AboutUs/map-img-mobile.svg';

import SirocaLogo from '../../../../assets/images/AboutUs/siroca.svg';

import { useMediaQuery } from '@mui/material';

const MapSection = () => {
  const isMobile = useMediaQuery('(max-width: 633px)');

  return (
    <div
      className="MapSection"
      style={{ background: `url(${isMobile ? MapImgMobile : MapImg})` }}
    >
      <PageContainer>
        <div className="MapSection__wrapper">
          <img src={SirocaLogo} alt="" />
          <Title lineHeight={'30px'} sizeFont={'24px'}>Laboratory Information Management System (LIMS)</Title>
          <Description color={'#FFF'} lineHeight={'24px'}>
            Siroca’s LIMS is built based on years of experience and expertise in laboratory technology. This comprehensive solution enables the automation of every facet of business processes, spanning from sample reception and registration to generating finalized results and assembling reports
          </Description>
        </div>
      </PageContainer>
    </div>
  );
};

export default MapSection;
