import React from 'react';
import RowItemCard from '../RowItemCard/RowItemCard';
import './RowItem.scss';

const RowItem = ({ title, data }) => {
  return (
    <div className="rowItem">
      <p className="rowItem__title">{title}</p>
      <div className="rowItem__items">
        {data.map((item, index) => {
          return <RowItemCard key={index} itemData={item} />;
        })}
      </div>
    </div>
  );
};

export default RowItem;
