import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';
import './LaboratoryInformation.scss';
import LaboratoryInformationCard from './components/Cards/LaboratoryInformationCard';

import auditLogs from '../../../../assets/images/SirocaForDiagnostics/auditLogs.svg';
import accessControl from '../../../../assets/images/SirocaForDiagnostics/accessControl.svg';

const LaboratoryInformation = () => {
  return (
    <div className="laboratoryInformation">
      <PageContainer>
        <div className="laboratoryInformation__wrapper">
          <div className="laboratoryInformation__text">
            <Title color={'#1F1F1F'}>
              Laboratory Information Management System That Audits Everything,
              Controls Leakages & Drives Up Quality
            </Title>
            <Description color={'#1F1F1F'}>
              Simplify lab business operations with CrelioHealth LIMS System's
              advanced administrative tools. Our system allows you to easily
              track performance, maintain quality standards, streamline audits,
              and improve accuracy & productivity.
            </Description>
          </div>

          <div className="laboratoryInformation__cards">
            <LaboratoryInformationCard
              title={'Enhance Transparency With Audit Logs'}
              description={
                "One of the key features of our LIMS System is the Audit Logs. This feature ensures that every action and step is carefully recorded, providing complete audit-ability. Whether it's making changes to patient information or reports, booking orders, configuration changes, or modifying payments, you can effortlessly track and review each activity. This level of transparency and accountability helps maintain the highest quality assurance standards in your lab operations."
              }
              img={auditLogs}
            />
            <LaboratoryInformationCard
              title={'Improve Security With Access Control'}
              description={
                'Access Control is an essential aspect of our LIMS System. This feature empowers you to assign appropriate access rights to your staff, reducing the risk of unauthorized changes. It also enables efficient management of discounts, cancellations, and modifications to reports and patient information. By implementing controlled access, you can ensure data management & integrity to strengthen the overall security of your system. Safeguard your system from unauthorized access.'
              }
              img={accessControl}
            />
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default LaboratoryInformation;
