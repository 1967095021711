import React from 'react';
import PageLayout from '../components/PageLayout/PageLayout';
import Siroca from '../pages/Siroca/Siroca';
import SirocaForDiagnostics from '../pages/SirocaForDiagnostics/SirocaForDiagnostics';
import SirocaPatientEngagement from '../pages/SirocaPatientEngagement/SirocaPatientEngagement';
import SirocaInventory from '../pages/SirocaInventory/SirocaInventory';
import SirocaTechnologyPacs from '../pages/SirocaTechnologyPacs/SirocaTechnologyPacs';
import AboutUs from '../pages/AboutUs/AboutUs';
import Implementation from '../pages/Implementation/Implementation';
import Integrations from '../pages/Integrations/Integrations';

const routes = [
  {
    element: <PageLayout />,
    children: [
      {
        element: <Siroca />,
        path: '/',
      },
      {
        element: <SirocaForDiagnostics />,
        path: '/siroca-for-diagnostics',
      },
      {
        element: <SirocaPatientEngagement />,
        path: '/siroca-patient-engagement',
      },
      {
        element: <SirocaInventory />,
        path: '/siroca-inventory',
      },
      {
        element: <SirocaTechnologyPacs />,
        path: '/siroca-technology-pacs',
      },
      {
        element: <AboutUs />,
        path: '/about-us',
      },
      {
        element: <Implementation />,
        path: '/implementation',
      },
      {
        element: <Integrations />,
        path: '/integrations',
      },
    ],
  },
];

export default routes;
