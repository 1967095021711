import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';
import ReviewsCard from './components/ReviewsCard';

import './CustomerFeedbackPanel.scss';

// Import Partners Logos
import Invivo from './../../../../assets/images/PartnersLogos/Invivo.svg';
import Labnet from './../../../../assets/images/PartnersLogos/Labnet.svg';
import Human from './../../../../assets/images/PartnersLogos/human.svg';
import Dostar from './../../../../assets/images/PartnersLogos/dostar.svg';
import Bonetsky from './../../../../assets/images/PartnersLogos/Bonetsky.svg';
import Hayat from './../../../../assets/images/PartnersLogos/hayat.svg';
import Intermed from './../../../../assets/images/PartnersLogos/intermed.svg';
import MedikAs from './../../../../assets/images/PartnersLogos/medik-as.svg';
import Taurt from './../../../../assets/images/PartnersLogos/taurt.svg';
import Unimed from './../../../../assets/images/PartnersLogos/unimed.svg';
import VisualDiagnostics from './../../../../assets/images/PartnersLogos/visual-diagnostics.svg';
import Vitros from './../../../../assets/images/PartnersLogos/vitros.svg';
import { useRecoilValue } from 'recoil';
import { ReviewsState } from '../../../../recoil/Reviews/Reviews';

const CustomerFeedbackPanel = () => {
  const ReviewsData = useRecoilValue(ReviewsState);

  return (
    <div className="CustomerFeedbackPanel">
      <PageContainer>
        <div className="CustomerFeedbackPanel__wrapper">
          <div className="CustomerFeedbackPanel__wrapper-header">
            <Title>
              Siroca LIMS is Trusted by number of providers and patients
              Worldwide, working since 2009
            </Title>
            <Description>
              We serve medical diagnostic laboratories of varying sizes and
              complexities, delivering carefully designed solutions with a keen
              emphasis on details. Our commitment to user feedback allows us to
              create customized solutions that address the unique challenges
              faced by laboratories of any scale.
              <br />
              <br />
              Our dedicated approach aims to assist every laboratory in
              optimizing processes, improving efficiency, and maximizing return
              on investment. The core focus of our diagnostic laboratory
              software is to ensure customer satisfaction through tailored
              solutions that align with the specific needs of each laboratory.
              Join the Siroca family and experience a partnership committed to
              the success of your laboratory.
            </Description>
          </div>
          <div className="CustomerFeedbackPanel__wrapper-logos">
            <img src={Invivo} alt="Invivo" />
            <img src={Labnet} alt="Labnet" />
            <img src={Dostar} alt="Dostar" />
            <img src={Hayat} alt="Hayat" />
            <img src={Bonetsky} alt="Bonetsky" />
            <img src={Vitros} alt="Vitros" />
            <img src={VisualDiagnostics} alt="VisualDiagnostics" />
            <img src={Intermed} alt="Intermed" />
            <img src={Unimed} alt="Unimed" />
            <img src={Taurt} alt="Taurt" />
            <img src={MedikAs} alt="MedikAs" />
            <img src={Human} alt="Human" />
          </div>
          <div className="CustomerFeedbackPanel__wrapper-reviews">
            <div className="CustomerFeedbackPanel__wrapper-reviews__left">
              <Title>
                What Our Customers Often Say About Our Diagnostic Lab Software
              </Title>
              <Description>
                Uncover the experiences of our customers who have leveraged
                the power of Siroca LIMS
              </Description>
            </div>
            <div className="CustomerFeedbackPanel__wrapper-reviews__right">
              {ReviewsData.map((review, index) => (
                <ReviewsCard
                  key={index}
                  text={review.text}
                  name={review.name}
                  country={review.subtitle}
                />
              ))}
            </div>
          </div>
        </div>
      </PageContainer>
      <div className="CustomerFeedbackPanel__background">
        <PageContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="226"
            height="226"
            viewBox="0 0 226 226"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M96.8571 4.84286C96.8571 2.16822 94.6889 0 92.0143 0H72.6429C32.5233 0 0 32.5233 0 72.6429V221.157C0 223.832 2.16822 226 4.84286 226H92.0143C94.6889 226 96.8571 223.832 96.8571 221.157V133.986C96.8571 131.311 94.6889 129.143 92.0143 129.143H53.2714C50.5968 129.143 48.4286 126.975 48.4286 124.3V72.6429C48.4286 59.2697 59.2697 48.4286 72.6429 48.4286H92.0143C94.6889 48.4286 96.8571 46.2603 96.8571 43.5857V4.84286ZM226 4.84286C226 2.16822 223.832 0 221.157 0H201.786C161.666 0 129.143 32.5233 129.143 72.6429V221.157C129.143 223.832 131.311 226 133.986 226H221.157C223.832 226 226 223.832 226 221.157V133.986C226 131.311 223.832 129.143 221.157 129.143H182.414C179.74 129.143 177.571 126.975 177.571 124.3V72.6429C177.571 59.2697 188.413 48.4286 201.786 48.4286H221.157C223.832 48.4286 226 46.2603 226 43.5857V4.84286Z"
              fill="#30CFB9"
            />
          </svg>
        </PageContainer>
      </div>
    </div>
  );
};

export default CustomerFeedbackPanel;
