import React from 'react';
import Title from '../../../../../components/Title/Title';
import Subtitle from '../../../../../components/Subtitle/Subtitle';

const MultipleModalitiesCards = ({ title, description, icon, bgColor }) => {
  return (
    <div className="MultipleModalities__card" style={{background: bgColor ? bgColor : 'fff'}}>
      <div className="MultipleModalities__card__imgContainer">
        <div
          dangerouslySetInnerHTML={{
            __html: icon,
          }}
        />
      </div>

      <div className="MultipleModalities__card__text">
        <Title color={'#1F1F1F'} sizeFont={'24px'}>
          {title}
        </Title>
        <Subtitle>{description}</Subtitle>
      </div>
    </div>
  );
};

export default MultipleModalitiesCards;
