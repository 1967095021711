import React from 'react';

import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';

import aboutSirocaImage from '../../../../assets/aboutSirocaImage.svg';
import aboutSirocaMobile from '../../../../assets/images/Siroca/aboutSirocaMobile.svg';
import { CubeIcon } from '../../../../assets/icons/Icons';

import './AboutSiroca.scss';
import { useRecoilValue } from 'recoil';
import { AboutSirocaState } from '../../../../recoil/AboutSiroca/AboutSiroca';
import { useMediaQuery } from '@mui/material';

const AboutSiroca = () => {
  const aboutSirocaList = useRecoilValue(AboutSirocaState);

  const isMobile = useMediaQuery('(max-width: 633px)');

  return (
    <div className="container">
      <div className="about-siroca">
        <div className="about-siroca__textContainer">
          <Title color={'#1F1F1F'}>
            Optimize Your Lab Efficiency with SIROCA LIMS: <br /> Streamlining
            Operations for Precision and Seamless Data Management
          </Title>
        </div>

        <div className="about-siroca__flexxer">
          <div className="about-siroca__flexxer__rightSide">
            <div className="about-siroca__flexxer__LIMS">
              <Title color={'#1F1F1F'} sizeFont={'24px'}>
                Laboratory Information Management System (LIMS)
              </Title>

              <ul className="about-siroca__flexxer__LIMS__list">
                {aboutSirocaList.map(item => (
                  <li className="about-siroca__flexxer__LIMS__list__item">
                    <div style={{ width: '20px', height: '20px' }}>
                      <CubeIcon />
                    </div>
                    <Description>
                      <div dangerouslySetInnerHTML={{ __html: item }} />
                    </Description>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <img
            src={isMobile ? aboutSirocaMobile : aboutSirocaImage}
            className="about-siroca__flexxer__image"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutSiroca;
