import { Logotype } from '../../assets/icons/Icons';
import NavDropdown from './components/Dropdown/NavDropdown';
import { Link, useLocation } from 'react-router-dom';
import { ButtonPrimary, ButtonPrimaryWithAction } from '../Button/Buttons';
import PageContainer from '../PageContainer/PageContainer';
import './header.scss';
import { useEffect, useState } from 'react';
import DropdownMenu from './components/DropdownMenu/DropdownMenu';

import { ClickAwayListener } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HeaderState } from '../../recoil/Header/Header';
import DropdownMobile from './components/DropdownMobile/DropdownMobile';
import { PricingPopupState } from '../../recoil/PricingPopupState/PricingPopupState';
import { ContactUsPopupState } from '../../recoil/ContactUsPopup/ContactUsState';

const Header = () => {
  const headerState = useRecoilValue(HeaderState);
  const [options, setOptions] = useState(headerState);

  const [openedMenuData, setOpenedMenuData] = useState(null);
  const [openedBurgerMobile, setOpenedBurgeMobile] = useState(false);

  const popupState = useRecoilValue(PricingPopupState);
  const [contactPopup, setContactPopup] = useRecoilState(ContactUsPopupState);

  const location = useLocation();

  const handleDropdownChange = isActive => {
    const updatedOptions = options.map(item =>
      item.name === 'Products'
        ? { ...item, isActive }
        : { ...item, isActive: false },
    );
    setOptions(updatedOptions);
  };

  const handleClose = () => {
    const activeOption = options.find(item => item.isActive && item.isDropDown);

    if (activeOption) {
      activeOption.isActive = false;
      setOptions([...options]);
    }
  };

  useEffect(() => {
    const openedMenu = options.find(item => item.isActive);

    setOpenedMenuData(null);
    setTimeout(() => {
      setOpenedMenuData(openedMenu);
    }, 300);
  }, [options]);

  useEffect(() => {
    handleClose();
  }, [location]);

  useEffect(() => {
    if (popupState || contactPopup) {
      handleDropdownChange(false);
    }
  }, [popupState, contactPopup]);

  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <header className="header">
        <PageContainer>
          <Link to={'/'}>
            <Logotype />
          </Link>

          <nav className="header__nav">
            {options.map(item =>
              item.isDropDown ? (
                <NavDropdown
                  key={item.name}
                  handleDropdownChange={handleDropdownChange}
                  name={item.name}
                  data={item}
                />
              ) : (
                <Link
                  key={item.name}
                  to={item.link}
                  className="header__nav__link"
                >
                  {item.name}
                </Link>
              ),
            )}

            <a className="header__nav__contact" href="callto:+77770120026">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="10" cy="10" r="10" fill="white" />
                <path
                  d="M7.14286 16C6.82857 16 6.55962 15.8933 6.336 15.6798C6.112 15.466 6 15.2091 6 14.9091V5.09091C6 4.79091 6.112 4.534 6.336 4.32018C6.55962 4.10673 6.82857 4 7.14286 4H12.8571C13.1714 4 13.4406 4.10673 13.6646 4.32018C13.8882 4.534 14 4.79091 14 5.09091V14.9091C14 15.2091 13.8882 15.466 13.6646 15.6798C13.4406 15.8933 13.1714 16 12.8571 16H7.14286ZM7.14286 13.2727V14.9091H12.8571V13.2727H7.14286ZM10 14.6364C10.1619 14.6364 10.2977 14.584 10.4074 14.4793C10.5168 14.3749 10.5714 14.2455 10.5714 14.0909C10.5714 13.9364 10.5168 13.8069 10.4074 13.7025C10.2977 13.5978 10.1619 13.5455 10 13.5455C9.8381 13.5455 9.70248 13.5978 9.59314 13.7025C9.48343 13.8069 9.42857 13.9364 9.42857 14.0909C9.42857 14.2455 9.48343 14.3749 9.59314 14.4793C9.70248 14.584 9.8381 14.6364 10 14.6364ZM7.14286 12.1818H12.8571V6.72727H7.14286V12.1818ZM7.14286 5.63636H12.8571V5.09091H7.14286V5.63636Z"
                  fill="#115AFB"
                />
              </svg>
              +7 777 012 00 26
            </a>
            <a className="header__nav__contact" href="mailto:office@siroca.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="10" cy="10" r="10" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.05879 6.81528C5.7421 6.81528 5.4813 7.07608 5.4813 7.39277V13.3036C5.4813 13.6203 5.7421 13.8811 6.05879 13.8811H13.9398C14.2565 13.8811 14.5173 13.6203 14.5173 13.3036V7.39277C14.5173 7.07608 14.2565 6.81528 13.9398 6.81528H6.05879ZM4.66602 7.39277C4.66602 6.62582 5.29183 6 6.05879 6H13.9398C14.7068 6 15.3326 6.62582 15.3326 7.39277V13.3036C15.3326 14.0705 14.7068 14.6963 13.9398 14.6963H6.05879C5.29183 14.6963 4.66602 14.0705 4.66602 13.3036V7.39277Z"
                  fill="#115AFB"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.73974 7.15926C4.86884 6.97482 5.12302 6.92997 5.30746 7.05907L9.99935 10.3434L14.6912 7.05907C14.8757 6.92997 15.1299 6.97482 15.259 7.15926C15.3881 7.3437 15.3432 7.59787 15.1588 7.72698L10.2331 11.1749C10.0928 11.2732 9.90594 11.2732 9.76558 11.1749L4.83992 7.72698C4.65549 7.59787 4.61063 7.3437 4.73974 7.15926Z"
                  fill="#115AFB"
                />
              </svg>
              office@siroca.com
            </a>

            <ButtonPrimaryWithAction
              action={() => setContactPopup(true)}
              bgColor={'#fff'}
              color={'blue'}
            >
              Book A Demo
            </ButtonPrimaryWithAction>
          </nav>
          <button
            className="header__burger-btn dropdown"
            onClick={() => setOpenedBurgeMobile(prev => !prev)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              className="header__burger"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.000327216 15.4097C0.0223006 28.8104 1.18796 29.9775 14.5861 29.9997L15 30L15.4139 29.9997C28.812 29.9775 29.9777 28.8104 29.9997 15.4097L30 14.9998C30 1.21301 28.9474 0.0224329 15.4139 0H14.5861C1.05263 0.0224329 0 1.21301 0 14.9998L0.000327216 15.4097ZM9 8C8.44772 8 8 8.44771 8 9C8 9.55228 8.44772 10 9 10H21C21.5523 10 22 9.55228 22 9C22 8.44771 21.5523 8 21 8H9ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H21C21.5523 16 22 15.5523 22 15C22 14.4477 21.5523 14 21 14H9ZM8 21C8 20.4477 8.44772 20 9 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H9C8.44772 22 8 21.5523 8 21Z"
                fill="white"
              />
            </svg>
          </button>
        </PageContainer>
        {
          <DropdownMenu
            cards={openedMenuData?.children}
            isOpened={!!openedMenuData}
            setOptions={setOptions}
            options={options}
            handleDropdownChange={handleDropdownChange}
          />
        }
        <DropdownMobile
          cards={openedMenuData?.children}
          isOpened={!!openedBurgerMobile}
          onClose={() => setOpenedBurgeMobile(false)}
        />
      </header>
    </ClickAwayListener>
  );
};

export default Header;
