import React from 'react';

import './Description.scss';

const Description = ({ children, color, lineHeight }) => {
  return (
    <p
      className="description"
      style={{ color: color || '#484848', lineHeight: lineHeight || '24px' }}
    >
      {children}
    </p>
  );
};

export default Description;
