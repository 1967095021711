import React from 'react';

import './MultipleModalities.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import Description from '../../../../components/Description/Description';
import { useRecoilValue } from 'recoil';
import { MultipleModalitiesState } from '../../../../recoil/MultipleModalities/MultipleModalities';
import MultipleModalitiesCards from './components/MultipleModalitiesCards';

const MultipleModalities = () => {
    const cards = useRecoilValue(MultipleModalitiesState)
  return (
    <div className="MultipleModalities">
      <PageContainer>
        <div className="MultipleModalities__wrapper">
          <div className="MultipleModalities__wrapper__text">
            <Title>Single Application To Connect Multiple Modalities</Title>
            <Description>
              An easy installable single application to connect all types of
              devices that support DICOM
            </Description>
          </div>

          <div className="MultipleModalities__wrapper__cards">
            {
                cards.map(({title, description, icon}) => (
                    <MultipleModalitiesCards title={title} description={description} icon={icon} bgColor='#EAF0FB'/>
                ))
            }
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default MultipleModalities;
