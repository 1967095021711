import { atom } from 'recoil';

import SirocaLims from './../../assets/images/SirocaForDiagnostics/SirocaForDiagnosticsLIMS.png';
import SirocaLims3 from './../../assets/images/SirocaForDiagnostics/SirocaLims3.png';
import SirocaForDiagnosticsTab from './../../assets/images/SirocaForDiagnostics/SirocaDiagnosticsTab.png';
import SirocaForDiagnosticsProccessing from './../../assets/images/SirocaForDiagnostics/SirocaForDiagnosticsProccessing.png';
import SirocaForDiagnosticsSafe from './../../assets/images/SirocaForDiagnostics/SirocaForDiagnosticsSafe.png';

export const SystemImproves = atom({
  key: 'systemImproves',
  default: [
    {
      tabName: 'Registration and Front Desk',
      tabContent:
        'Our Laboratory Information Management System (LIMS) optimizes every aspect of front-desk operations, spanning from registration to sample collection. This all-encompassing solution delivers a smooth and all inclusive experience for your laboratory, enabling effortless management of workflows from initiation to completion within a single platform. With features including customizable forms, automated communication, and seamless integration with online payment systems, our system ensures a convenient experience for both your B2B partners and direct to patient services.',
      tabImage: SirocaLims,
    },
    {
      tabName: 'Sample Collection and Sorting',
      tabContent:
        "To facilitate the effective processing of order requests, it's crucial to collect samples following standardized procedures. This can be accomplished by following the guidelines outlined in the B D Vacutainer collection guide. Alternatively, streamline sample collection at the center by utilizing the automated sample collection and preset capabilities offered by our laboratory LIMS System",
      tabImage: SirocaForDiagnosticsTab,
    },
    {
      tabName: 'Ease Your Test Sample Accessioning',
      tabContent:
        'The essential aspects of sample management, such as identification, segregation, outsourcing, and archiving, rely on the critical steps of printing and labeling samples with barcodes. By employing barcode labels, it becomes easy to assess whether samples align with your quality criteria, facilitating efficient decision-making on acceptance or rejection. Furthermore, the ability to directly scan the barcode allows for convenient sample retrieval at any desired location',
      tabImage: SirocaLims3,
    },
    {
      tabName: 'Processing',
      tabContent:
        'Enhance the efficiency of laboratory testing by streamlining sample processing and automating various tasks. This contributes to the reduction of operational expenses and the improvement of test accuracy. Employing a Laboratory Information Management System proves to be a robust solution for the effective management of samples, optimizing lab turnaround time, and ensuring smooth operations and dependable results',
      tabImage: SirocaForDiagnosticsProccessing,
    },
    {
      tabName: 'Seamless and Fast Lab Reporting',
      tabContent:
        'Optimize the process of entering test reports with our intuitive reporting interface. Our laboratory information management systems empower you to collect crucial patient data for generating precise reports. Additionally, we offer automated reporting through interfaces, diverse test templates, the ability to input report values in bulk through Excel, and even speech-to-text entry for convenience',
      tabImage: SirocaLims,
    },
    {
      tabName: 'Safe and Dependable Lab Report Approval',
      tabContent:
        "To ensure the accuracy of laboratory reports for your patients, it's crucial to implement a standardized approval process while also ensuring timely report delivery. This can be achieved by enabling signing physicians to authenticate and validate reports through secure logins on either the Lab Information Management System (LIMS) or the dedicated Doctor's App",
      tabImage: SirocaForDiagnosticsSafe,
    },
    {
      tabName: 'Reliable Timely Delivery of Laboratory Reports!',
      tabContent:
        'Siroca LIMS premier LIMS System ensures swift delivery of reports to patients and healthcare providers immediately upon availability. Our advanced laboratory LIMS System incorporates cutting-edge tracking features to meticulously record dispatched lab reports and implements effective strategies to optimize the speed of report delivery',
      tabImage: SirocaLims,
    },
  ],
});
