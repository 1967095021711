import React from 'react';
import './Title.scss';

const Title = ({ children, color, sizeFont, lineHeight }) => {
  return (
    <h2
      className="title"
      style={{ color: color, fontSize: sizeFont || '32px', lineHeight: lineHeight || '40px' }}
    >
      {children}
    </h2>
  );
};

export default Title;
