import React from 'react';
import './Subtitle.scss';

const Subtitle = ({ children, color }) => {
  return (
    <p className="subtitle" style={{ color: color || '#808080' }}>
      {children}
    </p>
  );
};

export default Subtitle;
