import React from 'react';
import './Solutions.scss';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import RowItem from './components/RowItem/RowItem';

import './Solutions.scss';
import { useRecoilValue } from 'recoil';
import { SolutionsDataState } from '../../../../recoil/Solutions/Solutions';

const Solutions = () => {
  const SolutionsData = useRecoilValue(SolutionsDataState);

  return (
    <div className={`solutions`}>
      <PageContainer>
        <div className="solutions__wrapper">
          <RowItem data={SolutionsData.Applications} title={'Applications'} />
          <RowItem
            data={SolutionsData.PopularUseCases}
            title={'Popular Use Cases'}
          />
        </div>
      </PageContainer>
    </div>
  );
};

export default Solutions;
