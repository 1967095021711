import { atom } from 'recoil';

export const AboutSirocaState = atom({
  key: 'AboutSirocaState',
  default: [
    'Integration with any external information system',
    'Integration with more than 200 types of medical <br/> equipment',
    'Analytical reporting module',
    'Warehouse/Inventory module',
    'Customer Relationship Management (CRM) module',
    "Laboratory Partner's office",
    'Quality Control',
  ],
});
