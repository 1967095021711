import React from 'react';
import HeaderCard from '../../../Cards/HeaderCard/HeaderCard';

import './DropdownMenu.scss';

const DropdownMenu = ({ cards, isOpened, handleDropdownChange }) => {
  const className = `dropdownMenu ${isOpened ? 'opened' : ''}`;

  return (
    <div className={className} onMouseLeave={() => handleDropdownChange(false)}>
      {cards?.map(card => (
        <HeaderCard
          key={card.name}
          text={card.name}
          description={card.description}
          icon={card.icon}
          knowMoreLink={card.link}
          pricingLink={'/'}
        />
      ))}
    </div>
  );
};

export default DropdownMenu;
