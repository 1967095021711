import React from 'react';
import './Integrations.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import IntegrationsImage from './../../assets/images/Integrations/Integrations.png';
import { useRecoilValue } from 'recoil';
import { SmoothLabSoftwareState } from '../../recoil/Intergrations/Intergrations';
import MakeYourLabFuture from '../Siroca/components/MakeYourLabFuture/MakeYourLabFuture';
import MedicalSoftwareSupport from '../Siroca/components/MedicalSoftwareSupport/MedicalSoftwareSupport';
import HealthcareSoftwareCertifications from '../Siroca/components/HealthcareSoftwareCertifications/HealthcareSoftwareCertifications';
import StickyBlock from './components/StickyBlock/StickyBlock';
import { useMediaQuery } from '@mui/material';

import MedicalSoftwareSupportImage from './../../assets/images/Siroca/MedicalDiagnostics.svg';
import MedicalSoftwareSupportImageMobile from './../../assets/images/Siroca/MedicalDiagnosticsMobile.png';
import Seamlessintegration from '../Siroca/components/Seamlessintegration/Seamlessintegration';

const Integrations = () => {
  const cards = useRecoilValue(SmoothLabSoftwareState);
  const isMobile = useMediaQuery('(max-width: 633px)');

  return (
    <div className="Integrations">
      <PageTitle
        hideBtn
        title={`Committed to Providing Exceptional Technological Experiences`}
        description={`We create a 360-degree solution that addresses every facet of your laboratory to deliver outstanding results`}
        image={IntegrationsImage}
      />
      <Seamlessintegration
        background={'#EAF0FB'}
        title={
          'Seamless integration of laboratory software to harmonize a diverse range of systems without data complexities'
        }
        description="We collaborate with both global and local enterprises to develop tailored solutions that suit your requirements"
        cardsBg={'#fff'}
        cards={cards}
      />

      <StickyBlock />

      <MedicalSoftwareSupport
        whiteMode
        title={
          'Continuous Expert Assistance: Your Medical Diagnostics Software Supported 24/7'
        }
        description={
          'We provide ongoing support after your purchase. Set up your facility, train staff, and address any troubleshooting needs in the Diagnostic Lab Software.'
        }
        image={
          isMobile
            ? MedicalSoftwareSupportImageMobile
            : MedicalSoftwareSupportImage
        }
      />
      <HealthcareSoftwareCertifications background={'#EAF0FB'} />

      <MakeYourLabFuture />
    </div>
  );
};

export default Integrations;
