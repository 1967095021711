import { atom } from 'recoil';

import Integration1 from '../../assets/images/Integrations/Integration1.png';
import Integration2 from '../../assets/images/Integrations/Integration2.png';
import Integration3 from '../../assets/images/Integrations/Integration3.png';
import Integration4 from '../../assets/images/Integrations/Integration4.png';
import Integration5 from '../../assets/images/Integrations/Integration5.png';
import Integration6 from '../../assets/images/Integrations/Integration6.png';

export const SmoothLabSoftwareState = atom({
  key: 'SmoothLabSoftwareState',
  default: [
    {
      title: 'Integration',
      description:
        'We seamlessly incorporate cutting-edge technologies, leveraging industry-standard practices, to enhance and streamline your laboratory operations. Our commitment to integration ensures that your lab stays at the forefront of technological advancements.',
    },
    {
      title: 'Security',
      description:
        'We prioritize privacy and protection, implementing robust measures to safeguard your data. Our system offers convenient data backup options and facilitates efficient data mining, providing you with a secure and reliable platform for your laboratory information.',
    },
    {
      title: 'Convenient Integration',
      description:
        'Our dedicated tech support team is readily available for communication and collaboration, ensuring smooth integration with various laboratory instruments, EMR systems, insurance companies, and other relevant entities. We stay connected to adapt and integrate seamlessly with your evolving needs.',
    },
    {
      title: 'Cost-Effective',
      description:
        'We establish a cost-effective partnership aimed at enhancing the diagnostic field. Our solutions are designed to optimize resources, ensuring efficiency and affordability without compromising on the quality of service. This commitment to cost-effectiveness makes our LIMS a valuable choice for advancing diagnostic capabilities',
    },
  ],
});

export const StickyTabs = atom({
  key: 'StickyTabs',
  default: [
    {
      name: 'ENR',
      id: 'ehr',
    },
    {
      name: 'Billing And Claims Tools',
      id: 'billing-and-claims-tools',
    },
    {
      name: 'Middleware',
      id: 'middleware',
    },
    {
      name: 'Equipment',
      id: 'equipment',
    },
    {
      name: 'MIS',
      id: 'mis',
    },
    {
      name: 'Other Technologies',
      id: 'other-technologies',
    },
  ],
});

export const StickyCards = atom({
  key: 'StickyCards',
  default: [
    {
      title: 'EHR',
      description:
        "Enhance your laboratory's interoperability by seamlessly integrating with leading EMR systems. Siroca LIMS ensures that your laboratory data seamlessly flows into your electronic medical records, providing a comprehensive view of patient information and streamlining your diagnostic processes. This integration not only promotes efficiency but also enhances the overall quality of patient care.",
      images: [
        {
          image: Integration1,
        },
      ],
      id: 'ehr',
      imgColumns: 'one',
    },
    {
      title: 'Billing And Claims Tools',
      description:
        'Simplify your financial workflows with Siroca LIMS, which seamlessly integrates with billing and claims tools. From accurate coding to efficient claims processing, our system ensures a smooth transition of data between laboratory operations and billing platforms. This integration not only reduces administrative burdens but also maximizes revenue capture, allowing your laboratory to thrive in a financially optimized environment.',
      images: [
        {
          image: Integration2,
        },
      ],
      id: 'billing-and-claims-tools',
      imgColumns: 'one',
    },
    {
      title: 'Middleware',
      description:
        'Siroca LIMS embraces middleware integration to bridge the communication gap between diverse laboratory instruments and software systems. By seamlessly connecting with middleware solutions, our LIMS optimizes data transfer, ensuring that your laboratory processes are not only efficient but also error-free. This integration streamlines your workflows, allowing you to focus on delivering precise and timely results',
      images: [
        {
          image: Integration3,
        },
      ],
      id: 'middleware',
      imgColumns: 'one',
    },
    {
      title: 'Equipment',
      description:
        'Connect your laboratory instruments effortlessly with Siroca LIMS. Our system integrates seamlessly with a wide array of equipment, ensuring that data generated by instruments is automatically captured and processed within the LIMS. This integration not only reduces manual data entry but also enhances the accuracy and reliability of your laboratory results.',
      images: [
        {
          image: Integration4,
        },
      ],
      id: 'equipment',
      imgColumns: 'one',
    },
    {
      title: 'MIS',
      description:
        'Siroca LIMS harmonizes with MIS, providing a comprehensive approach to managing information within your laboratory. By integrating with MIS, our system ensures that decision-makers have real-time access to critical data, enabling strategic planning and informed decision-making. This integration transforms your laboratory into a data-driven environment, driving continuous improvement and innovation.',
      images: [
        {
          image: Integration5,
        },
      ],
      id: 'MIS',
      imgColumns: 'one',
    },
    {
      title: 'Other Technologies',
      description:
        "Siroca LIMS takes a versatile approach to integration by accommodating a spectrum of other technologies crucial to your laboratory. Whether it's integrating with imaging systems, genomics platforms, or other specialized tools, our LIMS is designed to adapt and connect with the evolving landscape of laboratory technologies. This flexibility ensures that your laboratory stays at the forefront of innovation.",
      images: [
        {
          image: Integration6,
        },
      ],
      id: 'other-technologies',
      imgColumns: 'one',
    },
  ],
});
