import { atom } from 'recoil';

export const OurStatisticState = atom({
  key: 'OurStatisticState',
  default: {
    countries: 7,
    yearsOfExperience: 15,
    patientsPerYear: '30M',
    employees: '100+',
    users: '100k',
    implementedProjects: '250+',
  },
});
