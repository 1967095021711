import React from 'react';
import './RowItemCard.scss';

const RowItemCard = ({ itemData }) => {
  return (
    <div className="rowItemCard">
      <div className="rowItemCard__img">
        <div dangerouslySetInnerHTML={{ __html: itemData.icon }} />
      </div>
      <div className="rowItemCard__text">
        <p
          className="rowItemCard__text-title"
          style={{
            whiteSpace: itemData?.title?.length < 10 ? 'nowrap' : 'normal',
          }}
        >
          {itemData.title}
        </p>
      </div>
    </div>
  );
};

export default RowItemCard;
