import React from 'react';

import './OurStatistic.scss';
import { useRecoilValue } from 'recoil';
import { OurStatisticState } from '../../../../recoil/OurStatistic/OurStatistic';

const OurStatistic = () => {
  const ourStatistic = useRecoilValue(OurStatisticState);
  return (
    <div className="our-statistic">
      <div className="container">
        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">
            {ourStatistic.implementedProjects}
          </h2>
          <p className="our-statistic__frame__description">
            implemented projects
          </p>
        </div>
        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">
            {ourStatistic.employees}
          </h2>
          <p className="our-statistic__frame__description">employees </p>
        </div>

        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">{ourStatistic.users}</h2>
          <p className="our-statistic__frame__description">users</p>
        </div>
        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">
            {ourStatistic.patientsPerYear}
          </h2>
          <p className="our-statistic__frame__description">patients per year</p>
        </div>

        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">
            {ourStatistic.yearsOfExperience}
          </h2>
          <p className="our-statistic__frame__description">
            years of experience
          </p>
        </div>
        <div className="our-statistic__frame">
          <h2 className="our-statistic__frame__title">
            {ourStatistic.countries}
          </h2>
          <p className="our-statistic__frame__description">countries</p>
        </div>
      </div>
    </div>
  );
};

export default OurStatistic;
