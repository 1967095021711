import { atom } from 'recoil';

export const VideoSectionCardsState = atom({
  key: 'VideoSectionCardsState',
  default: [
    {
      title: 'Operational configuration',
      description: 'We will save you time and help you get started.',
    },
    {
      title: 'Simple interface',
      description:
        'Many intriguing features that you can start using without any learning curve.',
    },
    {
      title: '24 hour support',
      description: 'Our team is available 24 hours a day Integration-friendly.',
    },
    {
      title:
        'Our API, qualitatively and successfully can solve the problem of the users coming to it',
      description: '',
    },
  ],
});
