import React from 'react';

import './card.scss';

const Card = ({ icon, title, description, maxWidth }) => {
  return (
    <div className="card" style={{ maxWidth: maxWidth || '630px' }}>

      {icon && <div
        className="card__icon"
        dangerouslySetInnerHTML={{
          __html: icon,
        }}
      /> }

      <h3 className="card__title">{title}</h3>

      <p className="card__description">{description}</p>
    </div>
  );
};

export default Card;
