import React from 'react';
import './HeaderCard.scss';
import { ButtonOutlined, ButtonWithIcon } from '../../Button/Buttons';
import { useSetRecoilState } from 'recoil';
import { PricingPopupState } from '../../../recoil/PricingPopupState/PricingPopupState';

const HeaderCard = ({
  icon,
  text,
  description,
  knowMoreLink,
  pricingLink,
  onCloseDropdown,
}) => {
  const setOpenDialog = useSetRecoilState(PricingPopupState);
  return (
    <div className="header__card">
      <div className="header__card-top">
        {icon && React.cloneElement(icon)}
        <div className="header__card-top__text">
          <p className="title">
            <div
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </p>
          <span className="description">
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </span>
        </div>
      </div>
      <div className="header__card-bottom">
        <ButtonWithIcon action={onCloseDropdown} to={knowMoreLink}>
          Know More
        </ButtonWithIcon>
        <ButtonOutlined
          action={() => {
            setOpenDialog(true)
            if (onCloseDropdown) {
              onCloseDropdown()
            }
          }}
          color={'#115afb'}
          fontSize={'14px'}
        >
          Pricing
        </ButtonOutlined>
      </div>
    </div>
  );
};

export default HeaderCard;
