import { atom } from 'recoil';

export const FooterSocialState = atom({
  key: 'FooterSocialState',
  default: [
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <rect width="40" height="40" rx="20" fill="#115AFB"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9928 11H28.0064C28.5549 10.9999 28.9997 11.4444 29 11.9928V28.0064V28.0068C28.9999 28.5555 28.5551 29.0001 28.0064 29H23.4277V22.0394H25.7744L26.126 19.3148H23.4277V17.5795C23.4277 16.7925 23.6462 16.2562 24.7748 16.2562H26.2051V13.8259C25.9562 13.7928 25.1025 13.7188 24.109 13.7188C22.0348 13.7188 20.6152 14.9844 20.6152 17.3096V19.3148H18.2773V22.0394H20.6152V29H11.9936C11.4451 29.0001 11.0003 28.5556 11 28.0072V11.9936C10.9999 11.4451 11.4444 11.0003 11.9928 11Z" fill="white"/>
          </svg>`,
      link: '/',
    },
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115AFB"/>
      <path d="M16.6608 27.6287C23.4537 27.6287 27.1683 22.0011 27.1683 17.1212C27.1683 16.9614 27.165 16.8022 27.1579 16.6439C27.8788 16.1226 28.5056 15.4721 29 14.7316C28.3383 15.0257 27.6262 15.2236 26.8792 15.313C27.6417 14.8557 28.2271 14.1326 28.5031 13.2704C27.7896 13.6934 26.9994 14.0007 26.1581 14.1667C25.4842 13.4489 24.5247 13 23.4623 13C21.423 13 19.7691 14.6539 19.7691 16.6925C19.7691 16.9824 19.8016 17.2642 19.8649 17.5346C16.7956 17.3801 14.0738 15.9107 12.2526 13.6761C11.9355 14.2218 11.7526 14.8559 11.7526 15.5323C11.7526 16.8135 12.4046 17.9447 13.396 18.6063C12.7901 18.5876 12.221 18.4213 11.7234 18.1445C11.7229 18.16 11.7229 18.1751 11.7229 18.1916C11.7229 19.9802 12.9958 21.4735 14.6858 21.8119C14.3754 21.8963 14.0488 21.9418 13.712 21.9418C13.4744 21.9418 13.2429 21.9184 13.0178 21.8752C13.488 23.3425 14.8514 24.4103 16.4678 24.4402C15.2038 25.4309 13.6115 26.021 11.881 26.021C11.5832 26.021 11.2891 26.004 11 25.9699C12.6344 27.0175 14.5752 27.6288 16.661 27.6288" fill="white"/>
    </svg>`,
      link: '/',
    },
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115AFB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 11C13.2386 11 11 13.2386 11 16V24C11 26.7614 13.2386 29 16 29H24C26.7614 29 29 26.7614 29 24V16C29 13.2386 26.7614 11 24 11H16ZM26 15C26 15.5523 25.5523 16 25 16C24.4477 16 24 15.5523 24 15C24 14.4477 24.4477 14 25 14C25.5523 14 26 14.4477 26 15ZM20 24.5C22.4853 24.5 24.5 22.4853 24.5 20C24.5 17.5147 22.4853 15.5 20 15.5C17.5147 15.5 15.5 17.5147 15.5 20C15.5 22.4853 17.5147 24.5 20 24.5ZM20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z" fill="white"/>
    </svg>`,
      link: '/',
    },
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115AFB"/>
      <path d="M22.7626 11H17.2374C12.1953 11 11 12.1953 11 17.2374V22.7626C11 27.8047 12.1953 29 17.2374 29H22.7626C27.8047 29 29 27.8047 29 22.7626V17.2374C29 12.1953 27.7929 11 22.7626 11ZM25.5312 23.8403H24.2245C23.7295 23.8403 23.5771 23.4477 22.6867 22.5455C21.9133 21.7957 21.5707 21.6962 21.38 21.6962C21.1106 21.6962 21.0341 21.7721 21.0341 22.1417V23.3223C21.0341 23.639 20.9346 23.8291 20.0965 23.8291C18.7105 23.8291 17.1727 22.991 16.0944 21.4295C14.4683 19.1414 14.0233 17.4275 14.0233 17.0731C14.0233 16.8824 14.0993 16.7041 14.4655 16.7041H15.7749C16.1062 16.7041 16.2322 16.8566 16.361 17.2109C17.0084 19.0801 18.0867 20.7174 18.5316 20.7174C18.6959 20.7174 18.7718 20.6415 18.7718 20.2225V18.292C18.7218 17.4016 18.2532 17.3251 18.2532 17.009C18.2532 16.8566 18.3792 16.7041 18.5811 16.7041H20.6404C20.9188 16.7041 21.0212 16.8566 21.0212 17.1845V19.7888C21.0212 20.07 21.1472 20.1696 21.2231 20.1696C21.3874 20.1696 21.528 20.07 21.8323 19.7652C22.7728 18.7105 23.4466 17.0844 23.4466 17.0844C23.5344 16.8937 23.6868 16.7154 24.0181 16.7154H25.3276C25.7202 16.7154 25.8079 16.9173 25.7202 17.1986C25.5559 17.9602 23.9534 20.2219 23.9534 20.2219C23.8156 20.4503 23.7627 20.5498 23.9534 20.8046C24.094 20.9953 24.5513 21.3907 24.8556 21.7451C25.4153 22.3807 25.8456 22.9139 25.9603 23.2829C26.0892 23.6491 25.899 23.8398 25.5295 23.8398L25.5312 23.8403Z" fill="white"/>
    </svg>`,
      link: '/',
    },
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115AFB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 12.5038C11 12.105 11.1584 11.7225 11.4405 11.4405C11.7225 11.1584 12.105 11 12.5038 11H27.4945C27.6922 10.9997 27.888 11.0383 28.0707 11.1138C28.2533 11.1892 28.4194 11.3 28.5592 11.4396C28.699 11.5793 28.81 11.7452 28.8856 11.9278C28.9612 12.1104 29.0001 12.3062 29 12.5038V27.4945C29.0002 27.6922 28.9614 27.888 28.8859 28.0707C28.8104 28.2534 28.6996 28.4194 28.5598 28.5593C28.4201 28.6991 28.2541 28.81 28.0715 28.8856C27.8888 28.9612 27.6931 29.0001 27.4954 29H12.5038C12.3063 29 12.1106 28.9611 11.9281 28.8855C11.7456 28.8098 11.5798 28.699 11.4402 28.5593C11.3005 28.4195 11.1898 28.2536 11.1142 28.0711C11.0387 27.8886 10.9999 27.6929 11 27.4954V12.5038ZM18.1247 17.8629H20.5621V19.0869C20.9139 18.3833 21.8139 17.75 23.1664 17.75C25.7592 17.75 26.3736 19.1515 26.3736 21.7231V26.4865H23.7497V22.3089C23.7497 20.8444 23.3979 20.018 22.5045 20.018C21.2649 20.018 20.7495 20.909 20.7495 22.3089V26.4865H18.1247V17.8629ZM13.6247 26.3745H16.2495V17.75H13.6247V26.3736V26.3745ZM16.625 14.9371C16.6299 15.1618 16.59 15.3853 16.5074 15.5943C16.4248 15.8034 16.3013 15.9939 16.1441 16.1546C15.9869 16.3153 15.7992 16.4429 15.592 16.5301C15.3848 16.6173 15.1623 16.6622 14.9375 16.6622C14.7127 16.6622 14.4902 16.6173 14.283 16.5301C14.0758 16.4429 13.8881 16.3153 13.7309 16.1546C13.5737 15.9939 13.4502 15.8034 13.3676 15.5943C13.285 15.3853 13.2451 15.1618 13.25 14.9371C13.2597 14.496 13.4418 14.0762 13.7572 13.7677C14.0726 13.4591 14.4963 13.2863 14.9375 13.2863C15.3787 13.2863 15.8024 13.4591 16.1178 13.7677C16.4332 14.0762 16.6153 14.496 16.625 14.9371Z" fill="white"/>
    </svg>`,
      link: '/',
    },
    {
      image: `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="20" fill="#115AFB"/>
      <path d="M28.6243 15.9708C28.5219 15.5891 28.321 15.2411 28.0417 14.9615C27.7625 14.682 27.4147 14.4806 27.0333 14.3777C25.629 14 20 14 20 14C20 14 14.371 14 12.9667 14.3757C12.5851 14.4783 12.2371 14.6795 11.9579 14.9591C11.6786 15.2388 11.4778 15.587 11.3757 15.9688C11 17.375 11 20.308 11 20.308C11 20.308 11 23.2411 11.3757 24.6453C11.5826 25.4208 12.1933 26.0315 12.9667 26.2384C14.371 26.6161 20 26.6161 20 26.6161C20 26.6161 25.629 26.6161 27.0333 26.2384C27.8087 26.0315 28.4174 25.4208 28.6243 24.6453C29 23.2411 29 20.308 29 20.308C29 20.308 29 17.375 28.6243 15.9708ZM18.2121 23V17.6161L22.8728 20.2879L18.2121 23Z" fill="white"/>
    </svg>`,
      link: '/',
    },
  ],
});

export const FooterBlockState = atom({
  key: 'FooterBlockState',
  default: [
    {
      title: 'Products',
      links: [
        {
          name: 'Siroca Patient Engagement',
          to: '/siroca-patient-engagement',
        },
        {
          name: 'Siroca For Diagnostics',
          to: '/siroca-for-diagnostics',
        },
        {
          name: 'Siroca Technology PACS',
          to: '/siroca-technology-pacs',
        },
        {
          name: 'Siroca Inventory',
          to: '/siroca-inventory',
        },
        {
          name: 'Implementation',
          to: '/implementation',
        },
        {
          name: 'Integrations',
          to: '/integrations',
        },
      ],
    },
    {
      title: 'Lab Testing',
      links: [
        {
          name: 'Hematology',
          to: '/',
        },
        {
          name: 'Blood Chemistry',
          to: '/',
        },
        {
          name: 'Toxicology',
          to: '/',
        },
        {
          name: 'Anatomical',
          to: '/',
        },
        {
          name: 'Radiology',
          to: '/',
        },
        {
          name: 'Molecular',
          to: '/',
        },
      ],
    },
    {
      title: 'Common Use Cases',
      links: [
        {
          name: 'B2B Lab Management',
          to: '/',
        },
        {
          name: 'Multi-Location Labs',
          to: '/',
        },
        {
          name: 'Patient Experience',
          to: '/',
        },
        {
          name: 'Provider Portal',
          to: '/',
        },
        {
          name: 'Home Collection',
          to: '/',
        },
      ],
    },
    {
      title: 'Types of Labs',
      links: [
        {
          name: 'Physician Office Labs',
          to: '/',
        },
        {
          name: 'Hospital Based Labs',
          to: '/',
        },
        {
          name: 'D2C Labs',
          to: '/',
        },
        {
          name: 'Reference Labs',
          to: '/',
        },
      ],
    },
    {
      title: 'Company',
      links: [
        {
          name: 'About Us',
          to: '/about-us',
        },
        {
          name: 'Careers',
          to: '/',
        },
        {
          name: 'Contact Us',
          to: '/',
        },
        {
          name: 'Downloadable Resources',
          to: '/',
        },
      ],
    },
    {
      title: 'Company',
      links: [
        {
          name: 'Terms Of Use',
          to: '/',
        },
        {
          name: 'Privacy Policy',
          to: '/',
        },
        {
          name: 'Cancellation Policy',
          to: '/',
        },
        {
          name: 'Data Processing Agreement',
          to: '/',
        },
        {
          name: 'SLA and Support Services',
          to: '/',
        },
      ],
    },
  ],
});
