import React, { useEffect, useState } from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import './AccordionsSection.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

const AccordionsSection = ({
  bgColor,
  reversed,
  image,
  imageHeight,
  title,
  accordeons,
  subtitle,
  description,
  isUniqImages,
}) => {
  const [activeAccordeon, setActiveAccordeon] = useState(null);

  const isMobile = useMediaQuery('(max-width: 633px)');

  const handleChangeIsActive = (name, expanded) => {
    const updatedAccordeons = accordeons.map(accordeon => {
      if (accordeon.title === name) {
        accordeon.isActive = expanded;
      } else {
        accordeon.isActive = false;
      }
      return accordeon;
    });

    const hasActiveAccordeon = updatedAccordeons.some(
      accordeon => accordeon.isActive,
    );

    if (!hasActiveAccordeon && !expanded) {
      return;
    }

    setActiveAccordeon(
      expanded ? updatedAccordeons.find(acc => acc.title === name) : null,
    );
  };

  useEffect(() => {
    if (isUniqImages) {
      const expandedAccordeon = accordeons.find(
        accordeon => accordeon.isActive === true,
      );

      setActiveAccordeon(expandedAccordeon);
    }
  }, [accordeons]);
  return (
    <div
      className="AccordionsSection"
      style={{ background: bgColor || '#FFF' }}
    >
      <PageContainer>
        {title && <Title>{title}</Title>}
        <div
          className="AccordionsSection__wrapper"
          style={{ flexDirection: reversed ? 'row-reverse' : 'row' }}
        >
          <div className="AccordionsSection__text">
            <h3 className="AccordionsSection__text-title">{subtitle}</h3>
            <p className="AccordionsSection__text-text">{description}</p>
            {isMobile && !isUniqImages && (
              <div
                className="AccordionsSection__image mobile"
                style={{ height: imageHeight || '448px' }}
              >
                <img className="AccordionsSection__img" src={image} alt="" />
              </div>
            )}
            <div className="AccordionsSection__text-accordions">
              {accordeons?.map((accordeon, index) => (
                <Accordion
                  key={index}
                  className="AccordionsSection__text-accordions__accordion"
                  onChange={(event, expanded) =>
                    handleChangeIsActive(accordeon.title, expanded)
                  }
                  expanded={isUniqImages && accordeon.isActive}
                >
                  <AccordionSummary
                    className="AccordionsSection__text-accordions__accordion-title"
                    expandIcon={index === 0 && <NavigateNext color="#000" />}
                  >
                    {accordeon.title}
                  </AccordionSummary>
                  <AccordionDetails className="AccordionsSection__text-accordions__accordion-text">
                    {accordeon.description}

                    {isUniqImages && (
                      <img
                        className="AccordionsSection__mobile-image"
                        src={activeAccordeon?.image}
                        alt=""
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
          <div
            className="AccordionsSection__image"
            style={{ height: imageHeight || '448px' }}
          >
            {isUniqImages ? (
              <img src={activeAccordeon?.image} alt="" />
            ) : (
              <img src={image} alt="" />
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default AccordionsSection;
