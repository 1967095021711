import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './NavDropdown.scss';
import { useRecoilValue } from 'recoil';
import { PricingPopupState } from '../../../../recoil/PricingPopupState/PricingPopupState';
import { ContactUsPopupState } from '../../../../recoil/ContactUsPopup/ContactUsState';

const NavDropdown = ({ name, data, handleDropdownChange }) => {
  const openPopup = useRecoilValue(PricingPopupState);
  const contactPopup = useRecoilValue(ContactUsPopupState);

  useEffect(() => {
    if (openPopup || contactPopup) {
      handleDropdownChange(data.name, false);
    }
  }, [openPopup, contactPopup]);
  return (
    <button
      onMouseEnter={() => {
        handleDropdownChange(data.name, true);
      }}
      className="dropdown"
    >
      {name}
      <ExpandMoreIcon
        className={`dropdown__icon__${data.isActive ? 'rotated' : 'default'}`}
      />
    </button>
  );
};

export default NavDropdown;
