import React from 'react';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import Title from '../../../../components/Title/Title';
import ProductCard from './components/ProductCard/ProductCard';
import './OurProducts.scss';

const OurProducts = ({ background, cards, title, color , cardsBg}) => {
  return (
    <div
      className="OurProducts"
      style={{ backgroundColor: background && background }}
    >
      <PageContainer>
        <div className="OurProducts__wrapper">
          <Title color={color && color}>{title}</Title>
          <div className="OurProducts__wrapper-cards">
            {cards.map((product, index) => (
              <ProductCard
                key={index}
                title={product.title}
                description={product.description}
                image={product.image}
                linkTo={product.linkTo}
                hideBtn={product.hideBtn}
                cardsBg={cardsBg}
              />
            ))}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default OurProducts;
