import { atom } from 'recoil';

export const ReviewsState = atom({
  key: 'reviews',
  default: [
    {
      text: 'Our laboratories use Siroca LIMS for 15 years. The software works smoothly and meets all of our needs. Feedback from our clients is always positive, as it has allowed us to speed up and optimize our processes within our laboratories and improve the overall customer service experience.',
      name: 'INVIVO',
      subtitle: '№1 laboratory network in Kazakhstan',
    },
    {
      text: 'Implementing Siroca LIMS has been a transformative experience for our laboratory. The user-friendly interface facilitated a smooth transition for our staff, minimizing errors and increasing overall reliability. The software seamlessly integrated into our workflow, significantly boosting operational efficiency. Special thanks for the exceptional technical support which further underscores its value.',
      name: 'LABNET',
      subtitle: 'One of the top laboratories in Uzbekistan',
    },
    {
      text: 'During the last 10 years our company has been using the Siroca LIMS software. The system helps optimizing every step of the laboratory business processes, improves the efficiency of working with clients and helps solving day-to-day management problems. I would also like to highlight an advantage of the technical support service of Siroca.',
      name: 'Laboratory Bonetsky',
      subtitle: '№1 laboratory network in Kyrgyzstan',
    },
  ],
});
