import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import SirocaInventoryImage from '../../assets/images/SirocaInventory/SirocaInventoryTitle.png';
import ServiceHighlightSection from '../SirocaForDiagnostics/components/ServiceHighlightSection/ServiceHighlightSection';
import { useRecoilValue } from 'recoil';
import { InventoryManagementState } from '../../recoil/SirocaInventory/SirocaInventory';
import Solutions from '../Siroca/components/Solutions/Solutions';
import MakeYourLabFuture from '../Siroca/components/MakeYourLabFuture/MakeYourLabFuture';
import AccordionsSection from '../SirocaPatientEngagement/components/DropdownSection/AccordionsSection';

import RealTimeConsumption from '../../assets/images/SirocaInventory/RealTimeConsumptionAnalytics.png';
import AutomatedQC from '../../assets/images/SirocaInventory/AutomatedQC.png';
import AutomaticStock from '../../assets/images/SirocaInventory/AutomaticStockConsumption.png';
import LabInventoryPlanner from '../../assets/images/SirocaInventory/LabInventoryLedgerPlanner.png';

const SirocaInventory = () => {
  const inventoryManagementCards = useRecoilValue(InventoryManagementState);
  return (
    <div className="SirocaInventory">
      <PageTitle
        title={
          'Enhance Efficiency, Boost Profits, and Decrease losses with Siroca’s Laboratory Inventory Management Software'
        }
        description={
          "Minimize losses, decrease wastage, and cut down on inventory expenses with an 'information-focused' approach. Create space for improved quality and productivity through state-of-the-art automation and efficient inventory management."
        }
        image={SirocaInventoryImage}
      />

      <AccordionsSection
        subtitle={'Monitor Inventory Operations'}
        description={
          'Laboratories allocate a significant portion of their budget to inventory management, and maintaining control over thousands of items, reagents, and materials is a crucial aspect. Easily assess historical consumption patterns using charts and enhance stock management through accurate, filterable data for seamless operations'
        }
        accordeons={[
          {
            title: 'Dashboard',
            description:
              'Receive swift updates on below-alert and near-expiry stock levels via a unified, multi-view dashboard. Our lab inventory system allows you to monitor everything from wastage to financial aspects with precision. Simplify intricate procurement tasks by presenting all relevant data on a single screen',
            image: RealTimeConsumption,
            isActive: true,
          },
          {
            title: 'Usage Analytics',
            image: LabInventoryPlanner,
            description:
              'Record extensive data on laboratory equipment, instruments, and other items through our secure inventory planning and control platform. Prevent issues of excess or insufficient stock with visually accessible details.',
            isActive: false,
          },
        ]}
        isUniqImages
        imageHeight={'auto'}
      />
      <AccordionsSection
        bgColor={'#FAFAFA'}
        subtitle={
          'Siroca’s Inventory Management is designed for Small, Medium and Large labs with single center or multi-center operations'
        }
        isUniqImages
        imageHeight={'auto'}
        accordeons={[
          {
            title: 'All lab sizes',
            image: AutomaticStock,
            isActive: true,
            description:
              'Organize operations to monitor the purchase, usage, and loss of lab inventory across various laboratory types. We will tailor functionality based on your existing operations to enhance comfort and usability. Operate seamlessly across your main distribution centers, smaller hubs, and various locations',
          },
          {
            title: 'Navigate Logistics',
            isActive: false,

            description:
              'Create and configure profiles for various individuals and collection centers within the organization to manage logistics and operations efficiently. Simplify tasks for local administration and promote transparency',
          },
          {
            title: 'Easy Integration and Automation',
            image: AutomatedQC,
            isActive: false,

            description:
              'Eliminate manual data entries with our inventory management software equipped with a barcode scanner. Associate specific tests and samples with frequently used inventory items. Automate the consumption of bulk stocks using dedicated catalog codes.',
          },
        ]}
        reversed
      />

      <ServiceHighlightSection
        title={'Make the work of Lab Managers simplified'}
        cards={inventoryManagementCards}
        background={'#fff'}
      />
      <Solutions whiteMode />
      <MakeYourLabFuture />
    </div>
  );
};

export default SirocaInventory;
