import React from 'react';
import Subtitle from '../../../../../components/Subtitle/Subtitle';
import Title from '../../../../../components/Title/Title';
import Description from '../../../../../components/Description/Description';
import './ReviewsCard.scss';

const ReviewsCard = ({ text, name, country }) => {
  return (
    <div className="ReviewsCard">
      <div className="ReviewsCard__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 0.3C6 0.134315 5.86569 0 5.7 0H4.5C2.01472 0 0 2.01472 0 4.5V13.7C0 13.8657 0.134315 14 0.3 14H5.7C5.86569 14 6 13.8657 6 13.7V8.3C6 8.13431 5.86569 8 5.7 8H3.3C3.13431 8 3 7.86569 3 7.7V4.5C3 3.67157 3.67157 3 4.5 3H5.7C5.86569 3 6 2.86569 6 2.7V0.3ZM14 0.3C14 0.134315 13.8657 0 13.7 0H12.5C10.0147 0 8 2.01472 8 4.5V13.7C8 13.8657 8.13431 14 8.3 14H13.7C13.8657 14 14 13.8657 14 13.7V8.3C14 8.13431 13.8657 8 13.7 8H11.3C11.1343 8 11 7.86569 11 7.7V4.5C11 3.67157 11.6716 3 12.5 3H13.7C13.8657 3 14 2.86569 14 2.7V0.3Z"
            fill="#115AFB"
          />
        </svg>
      </div>
      <div className="ReviewsCard__review">
        <Subtitle color={'#484848'}>{text}</Subtitle>
        <Title color={'#115AFB'} sizeFont={'24px'} lineHeight={'30px'}>
          {name}
        </Title>
        <Description lineHeight={'24px'} color={'#808080'}>
          {country}
        </Description>
      </div>
    </div>
  );
};

export default ReviewsCard;
