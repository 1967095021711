import React from 'react';
import Title from '../../../../../../components/Title/Title';
import Subtitle from '../../../../../../components/Subtitle/Subtitle';

const LaboratoryInformationCard = ({ img, title, description }) => {
  return (
    <div className="laboratoryInformation__cards__card">
      <div className="laboratoryInformation__cards__card__image">
        <img src={img} alt="" />
      </div>

      <Title sizeFont={'24px'} lineHeight={'30px'} color={'#1F1F1F'}>
        {title}
      </Title>

      <Subtitle color={'#808080'}>{description}</Subtitle>
    </div>
  );
};

export default LaboratoryInformationCard;
