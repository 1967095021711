import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import AboutUsImage from '../../assets/images/AboutUs/AboutUsImage.png';
import OurStatistic from '../Siroca/components/OurStatistic/OurStatistic';
import Solutions from '../Siroca/components/Solutions/Solutions';
import MakeYourLabFuture from '../Siroca/components/MakeYourLabFuture/MakeYourLabFuture';
import OurProducts from '../Siroca/components/OurProducts/OurProducts';
import MapSection from './components/MapSection/MapSection';
import './AboutUs.scss';
import { useRecoilValue } from 'recoil';
import { OurProductsState } from '../../recoil/OurProducts/OurProducts';

const AboutUs = () => {
  const cards = useRecoilValue(OurProductsState);
  return (
    <div className="AboutUs">
      <PageTitle
        image={AboutUsImage}
        title={'Committed to Providing Exceptional Technological Experiences'}
        description={
          'Siroca specializes in conceptualizing, designing, and implementing technology-driven business solutions for top-tier companies. Additionally, we offer a comprehensive suite of services, utilizing our domain knowledge, business expertise, and collaborations with leading technology providers'
        }
        hideBtn
      />
      <OurStatistic />
      <MapSection />
      <OurProducts cards={cards} background={'#FAFAFA'} color={'#fff'} />
      <Solutions whiteMode />
      <MakeYourLabFuture />
    </div>
  );
};

export default AboutUs;
