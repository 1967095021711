import React from 'react';
import './Buttons.scss';
import { Link } from 'react-router-dom';
import { NavigateNext } from '@mui/icons-material';

export const ButtonOutlined = ({ children, to, action, color, fontSize }) => {
  return action ? (
    <button
      className={`button button__outlined`}
      style={{ color: color, fontSize: fontSize }}
      onClick={action}
    >
      {children}
    </button>
  ) : (
    <Link to={to}>
      <button className={`button button__outlined`}>{children}</button>
    </Link>
  );
};

export const ButtonPrimary = ({ children, to, bgColor, color, type }) => {
  return type ? (
    <button
      className={`button`}
      style={{ backgroundColor: bgColor, color: color }}
      type={type}
    >
      {children}
    </button>
  ) : (
    <Link to={to}>
      <button
        className={`button`}
        style={{ backgroundColor: bgColor, color: color }}
      >
        {children}
      </button>
    </Link>
  );
};

export const ButtonPrimaryWithAction = ({
  bgColor,
  color,
  children,
  action,
}) => {
  return (
    <button
      className={`button`}
      style={{ backgroundColor: bgColor, color: color }}
      onClick={action}
    >
      {children}
    </button>
  );
};

export const ButtonWithIcon = ({ children, to, action }) => {
  return (
    <Link to={to}>
      <button className={`button button__withIcon`} onClick={action}>
        {children} <NavigateNext sx={{ width: 20, height: 20 }} />
      </button>
    </Link>
  );
};
