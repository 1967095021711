import { atom } from 'recoil';

export const DifferentiatorsOfOurLabState = atom({
  key: 'DifferentiatorsOfOurLabState',
  default: [
    {
      title: 'Skilled Team of Integration Experts',
      text: 'Configure everything from slot booking availability',
    },
    {
      title: 'Your Source for Expertise',
      text: 'Reach Out to Professionals on Our Site easy connection',
    },
  ],
});
