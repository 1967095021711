import React from 'react';

import './PageTitle.scss';

const PageTitle = ({ title, description, image, bigTitle }) => {
  return (
    <div className="page-title__background">
      <div className="container">
        <div className="page-title">
          <div className="page-title__leftSide">
            <h1
              className="page-title__leftSide__title"
              dangerouslySetInnerHTML={{ __html: title }}
              style={{
                fontSize: bigTitle && '58px',
                lineHeight: bigTitle && '70px',
              }}
            />
            <p
              className="page-title__leftSide__description"
              style={{
                fontSize: bigTitle && '24px',
                lineHeight: bigTitle && '30px',
              }}
            >
              {description}
            </p>
          </div>
          <div className="page-title__rightSide">
            <img src={image} alt="" className="page-title__rightSide__image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
